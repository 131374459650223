import React from 'react'
import { useNavigate } from "react-router-dom"
import { Result, Button } from 'antd'
import { Helmet } from "react-helmet";
import language from "../../resources/languages/en_US"

const { App: { title }, Pages: { Payment: PaymentLanguage  }, Share, Validation, Message: MessageLanguage } = language

export default function PaymentSuccess() {

    const navigate = useNavigate()

    return (
        <div className={'payment-success'}>
            <Helmet>
                <title>{title} - {PaymentLanguage.successTitle}</title>
            </Helmet>
            <Result
                icon={<img alt={PaymentLanguage.successTitle} width={300} src={require('../../resources/media/payment/payment-success.png')} />}
                title={PaymentLanguage.successTitle}
                subTitle={PaymentLanguage.successDescription}
                extra={<Button type="link" className={'link-button'} onClick={() => navigate('/profile/my-account')}>{PaymentLanguage.successButton}</Button>}
            />
        </div>
    )
}
