import React, {useContext, useEffect, useState} from "react";
import {App, Input} from 'antd';
import validator from "validator";
import { API, Enum } from "../../resources/constants";
import language from "../../resources/languages/en_US";

// Interfaces
import { AxiosError } from "../../interfaces/axios"

// Contexts
import AxiosContext from "../../contexts/axios";

const { Pages: { Profile: { Members: MembersLanguage }  }, Share, Enum: EnumLanguage, Validation, Message: MessageLanguage } = language
const { USER_MANAGEMENT_PROFILE_MEMBER_INVITE } = API
const { Message } = Enum

export default function InviteNewMember(props: { Form: any, form: any, getMembers: () => void, setOpenModal: (openModal: boolean) => void }) {
    const { Form, form, getMembers, setOpenModal } = props

    const [, forceUpdate] = useState({})
    const { axios } = useContext(AxiosContext)
    const { message } = App.useApp()

    useEffect(() => {
        // To disable submit button at the beginning.
        forceUpdate({});
    }, []);

    const onFinish = (values: { email: string }) => {
        const { email } = values;
        axios.get(USER_MANAGEMENT_PROFILE_MEMBER_INVITE + email).then(() => {
            getMembers()
            form.resetFields()
            setOpenModal(false)
            message.success(MessageLanguage[Message.INVITE_MEMBER]).then(response => response)
        }, (error: AxiosError) => {
            const { response: { data } } = error;
            // @ts-ignore
            message.error(MessageLanguage[data.message]).then(response => response)
        })
    }

    return (
        <Form layout={'vertical'} form={form} name="invite-new-member" autoComplete="off" onFinish={onFinish}>
            <Form.Item
                label={MembersLanguage.email}
                name="email"
                rules={[
                    { required: true, message: `${MembersLanguage.email} ${Validation.required}` },
                    () => ({
                        validator(_: any, value: string) {
                            if (!value || validator.isEmail(value)) return Promise.resolve()
                            else return Promise.reject(new Error(Validation.invalidEmail))
                        },
                    }),
                ]}
            >
                <Input placeholder={MembersLanguage.emailPlaceholder} />
            </Form.Item>
        </Form>
    )
}
