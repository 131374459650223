import React from 'react'
import { useNavigate } from "react-router-dom"
import { Result, Button } from 'antd'
import { Helmet } from "react-helmet";
import language from "../resources/languages/en_US"

const { App: { title }, Pages: { Forbidden }, Share } = language

export default function Index() {

    const navigate = useNavigate()

    return (
        <div className={'forbidden'}>
            <Helmet>
                <title>{title} - {Forbidden.title}</title>
            </Helmet>
            <Result
                status="403"
                title="403"
                subTitle={Forbidden.description}
                extra={<Button type="link" className={'link-button'} onClick={() => navigate('/')}>{Share.backHome}</Button>}
            />
        </div>
    )
}
