export enum Message {
    SIGN_IN_PROFILE = "SIGN_IN_PROFILE",
    SIGN_OUT_PROFILE = "SIGN_OUT_PROFILE",
    CHANGE_SUCCESS = "CHANGE_SUCCESS",
    UPDATE_PROFILE = "UPDATE_PROFILE",
    UPLOAD_SUCCESS = "UPLOAD_SUCCESS",
    UPLOAD_ERROR = "UPLOAD_ERROR",
    DELETE_MEMBER = "DELETE_MEMBER",
    ADD_MEMBER = "ADD_MEMBER",
    INVITE_MEMBER = "INVITE_MEMBER",
    DELETE_ACCOUNT = "DELETE_ACCOUNT",
    OTP_SUCCESS = "OTP_SUCCESS",
}

export enum Plan {
    FELIX_PRO = 'FELIX_PRO',
    FELIX_PRIME = 'FELIX_PRIME',
}

export enum Member {
    MEMBER = 'MEMBER',
    INVITE = 'INVITE',
}
