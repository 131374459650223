import React from "react";
import { Progress } from "antd";
import language from "../../resources/languages/en_US";
import pendingRequest from "../../resources/media/pending-request.svg";

const { Commons: { PendingRequest } } = language

export default function Index({ pendingRequests }: { pendingRequests: number }) {

    return (
        <div id={'pending-request'} className={'pending'} style={{ display: pendingRequests ? 'flex' : 'none'}}>
            {/*<Progress type="circle" className={'pending-progress'} percent={pendingRequests ? Math.round(100 / pendingRequests) : 0} strokeColor={{ '0%': '#c29202e8', '100%': '#facd02' }} format={() => <div className={'loading'}>{PendingRequest.loading}</div>} width={200} />*/}
            <img alt={PendingRequest.loading} src={pendingRequest} />
        </div>
    );
};
