import React, { useContext } from 'react'
import { Link, useLocation } from "react-router-dom"
import { isMobileOnly, isDesktop } from "react-device-detect"
import { Menu } from 'antd'
import { MdOutlineSpaceDashboard } from "react-icons/md";
import { FaBars, FaSearch, FaRegCommentAlt, FaQrcode, FaRegBell, FaRegUser, FaRegBookmark, FaColumns } from "react-icons/fa";
import language from "../../resources/languages/en_US";

// Contexts
import GlobalContext from '../../contexts/global'

const { Layouts: { Dashboard } } = language

export default function Index({ collapsed = false, setCollapsed }: { collapsed?: boolean, setCollapsed?: (collapsed: boolean) => void }) {

    const { user } = useContext(GlobalContext)
    const location = useLocation()

    return (
        <Menu
            theme="light"
            mode="inline"
            defaultSelectedKeys={['/']}
            defaultOpenKeys={[`/${location.pathname.split('/')[1]}`]}
            selectedKeys={[location.pathname]}
            onSelect={() => setCollapsed && setCollapsed(!collapsed)}
        >
            {
                [
                    {
                        key: '/',
                        icon: <div className={'ant-menu-item-icon-wrapper'}><MdOutlineSpaceDashboard /></div>,
                        label: <Link to="/">{Dashboard.dashboard}</Link>,
                        className: 'dashboard',
                    },
                    {
                        key: '/opportunities',
                        icon: <div className={'ant-menu-item-icon-wrapper'}><FaSearch /></div>,
                        label: <Link to="/opportunities">{Dashboard.opportunities}</Link>,
                        className: 'opportunities',
                    },
                    {
                        key: '/proposal-ai-agent',
                        icon: <div className={'ant-menu-item-icon-wrapper'}><FaRegCommentAlt size={20} /></div>,
                        label: <Link to="/proposal-ai-agent">{Dashboard.proposalAiAgent}</Link>,
                        className: 'proposal-ai-agent',
                    },
                    {
                        key: '/contract-management',
                        icon: <div className={'ant-menu-item-icon-wrapper'}><FaQrcode /></div>,
                        label: <Link to="/contract-management">{Dashboard.contractManagement}</Link>,
                        className: 'contract-management',
                    },
                    {
                        key: '/schedules',
                        icon: <div className={'ant-menu-item-icon-wrapper'}><FaRegBell /></div>,
                        label: <Link to="/schedules">{Dashboard.schedules}</Link>,
                        className: 'schedules',
                    },
                    {
                        key: '/pipelines',
                        icon: <div className={'ant-menu-item-icon-wrapper'}><FaBars /></div>,
                        label: <Link to="/pipelines">{Dashboard.pipelines}</Link>,
                        className: 'pipelines',
                    },
                    {
                        key: '/templates',
                        icon: <div className={'ant-menu-item-icon-wrapper'}><FaRegBookmark /></div>,
                        label: <Link to="/templates">{Dashboard.templates}</Link>,
                        className: 'templates',
                    },
                    {
                        key: '/knowledge-base',
                        icon: <div className={'ant-menu-item-icon-wrapper'}><FaColumns /></div>,
                        label: <Link to="/knowledge-base">{Dashboard.knowledgeBase}</Link>,
                        className: 'knowledge-base',
                    },
                    {
                        key: '/profile',
                        icon: <div className={'ant-menu-item-icon-wrapper'}><FaRegUser /></div>,
                        label: <Link to="/profile/my-account">{Dashboard.profile}</Link>,
                        className: 'profile',
                    }
                ].map(({key, icon, label, className}) => (
                    <Menu.Item key={key} icon={icon} className={(collapsed && isDesktop) ? `menu-item-collapsed ${className}` : `${className}`}>
                        {label}
                    </Menu.Item>
                ))
            }
        </Menu>
    )
}
