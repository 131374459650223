import React, { useState, useContext } from 'react'
import { Layout } from 'antd'
import { isMobileOnly, isDesktop } from "react-device-detect"
import language from "../../resources/languages/en_US";

// Components
import Content from './content'
import Footer from './footer'

const { Layouts: { Public } } = language

export default function Index() {

    return (
        <Layout className='public-layout'>
            <Content />
            <Footer />
        </Layout>
    )
}
