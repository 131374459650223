import React, { useContext, useEffect, useState } from 'react'
import { Space, Typography, Form, App, Input, Button, Flex } from 'antd'
import { Helmet } from "react-helmet"
import { FaTrashAlt, FaLockOpen } from "react-icons/fa";
import { useNavigate } from "react-router-dom"
import Cookies from "js-cookie";
import { ActionType, API, Enum } from "../../resources/constants";
import language from "../../resources/languages/en_US"

// Interfaces
import { AxiosError } from "../../interfaces/axios"

// Contexts
import GlobalContext from '../../contexts/global'
import AxiosContext from "../../contexts/axios";

const { App: { title }, Pages: { Profile: { DeleteAccount: DeleteAccountLanguage }  }, Share, Validation, Message: MessageLanguage } = language
const { USER_MANAGEMENT_PROFILE_ACCOUNT_DELETE } = API
const { SET_USER, SET_ACCESS_TOKEN } = ActionType
const { Message } = Enum

export default function DeleteAccount() {

    const [form] = Form.useForm()
    const [, forceUpdate] = useState({})
    const { axios } = useContext(AxiosContext)
    const { dispatch } = useContext(GlobalContext)
    const navigate = useNavigate()
    const { message } = App.useApp()

    useEffect(() => {
        // To disable submit button at the beginning.
        forceUpdate({});
    }, []);

    const onFinish = (values: { organizationName: string, password: string }) => {
        axios.post(USER_MANAGEMENT_PROFILE_ACCOUNT_DELETE, values).then(() => {
            Cookies.remove('accessToken')
            dispatch({ type: SET_ACCESS_TOKEN , payload: { accessToken: null }})
            dispatch({ type: SET_USER , payload: { user: null }})
            message.success(MessageLanguage[Message.DELETE_ACCOUNT]).then(response => response)
            navigate("/")
        }, (error: AxiosError) => {
            const { response: { data } } = error;
            // @ts-ignore
            message.error(MessageLanguage[data.message]).then(response => response)
        })
    };

    return (
        <div className={'delete-account'}>
            <Helmet>
                <title>{title} - {DeleteAccountLanguage.title}</title>
            </Helmet>
            <Space direction="vertical" size={50} style={{width:'100%'}}>
                <Typography.Title level={1}>{DeleteAccountLanguage.title}</Typography.Title>
                <Typography.Title level={2}>{DeleteAccountLanguage.description1}</Typography.Title>
                <Typography.Title level={3}>{DeleteAccountLanguage.description2}</Typography.Title>
                <Form layout={'vertical'} form={form} name="delete-account" autoComplete="off" onFinish={onFinish} >
                    <Form.Item
                        label={DeleteAccountLanguage.organizationName}
                        name="organizationName"
                        rules={[
                            { required: true, message: `${DeleteAccountLanguage.organizationName} ${Validation.required}` },
                        ]}
                    >
                        <Input placeholder={DeleteAccountLanguage.organizationNamePlaceholder} />
                    </Form.Item>
                    <Form.Item
                        label={DeleteAccountLanguage.password}
                        name="password"
                        rules={[
                            { required: true, message: `${DeleteAccountLanguage.password} ${Validation.required}` },
                        ]}
                    >
                        <Input.Password prefix={<FaLockOpen />} placeholder={DeleteAccountLanguage.passwordPlaceholder} />
                    </Form.Item>
                    <Flex gap={'middle'} justify={'end'} style={{marginTop:50}}>
                        <Form.Item shouldUpdate>
                            {() => (
                                <Button
                                    type="link"
                                    className={'link-button'}
                                    onClick={async () => form.resetFields()}
                                >
                                    {DeleteAccountLanguage.cancel}
                                </Button>
                            )}
                        </Form.Item>
                        <Form.Item shouldUpdate>
                            {() => (
                                <Button
                                    danger
                                    type="primary"
                                    htmlType="submit"
                                >
                                    <Space><FaTrashAlt size={15} /><span>{DeleteAccountLanguage.delete}</span></Space>
                                </Button>
                            )}
                        </Form.Item>
                    </Flex>
                </Form>
            </Space>
        </div>
    )
}
