import React from "react";
import { Flex } from "antd";

import language from "../../resources/languages/en_US";

const { Commons: { Intro } } = language

export default function Index() {
    return (
        <Flex gap="small" vertical={true} className={'intro'}>
            <div className={'intro-title'}>
                <h1>{Intro.title}</h1>
                <p>{Intro.description}</p>
            </div>
        </Flex>
    )
}
