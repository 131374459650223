import React, {useContext, useState, useEffect, MouseEvent} from 'react'
import _ from 'lodash'
import { Helmet } from 'react-helmet'
import { Row, Col, Input, Button, Space, Pagination, Card, Flex, Typography, Tag, Form, DatePicker, Checkbox, Select, AutoComplete, Empty, App } from 'antd'
import { TiArrowForwardOutline } from "react-icons/ti";
import { FaTimes, FaArrowCircleRight, FaSearch } from "react-icons/fa";
import dayjs, { Dayjs } from 'dayjs'
import { API, Enum, Regex } from "../../resources/constants"
import language from "../../resources/languages/en_US"

// Interfaces
import { Tender, Filter } from "../../interfaces/pages/opportunities"
import { ClassificationsForm } from "../../interfaces/pages/profile"
import { AxiosError } from "../../interfaces/axios"

// Contexts
import GlobalContext from "../../contexts/global";
import AxiosContext from "../../contexts/axios";

const { App: { title }, Pages: { Opportunities }, Share, Validation, Message: MessageLanguage, Enum: EnumLanguage } = language
const { TENDER_TENDER_PAGINATE, TENDER_NAICS_CODE_PAGINATE, TENDER_BUYER_PAGINATE, TENDER_PSC_CODE_PAGINATE, USER_MANAGEMENT_PROFILE_GET_CLASSIFICATION, TENDER_SET_ASIDE_CODE_PAGINATE, TENDER_NOTICE_TYPE_PAGINATE, TENDER_SOURCE_PAGINATE } = API;
const { Message } = Enum
const { isNumber } = Regex
const PVB = ['Max', 'Min'];
const PTS = ['Max', 'Min'];

export default function Index() {

    const { user } = useContext(GlobalContext)
    const { axios } = useContext(AxiosContext)
    const [pagination, setPagination] = useState<{
        page: number,
        pageSize: number,
        total: number,
    }>({
        page: 1,
        pageSize: 6,
        total: 0,
    });
    const [search, setSearch] = useState<string>('');
    const [filter, setFilter] = useState<Filter>({});
    const [showFilter, setShowFilter] = useState<boolean>(false);
    const [naicsCodes, setNAICSCodes] = useState<string[]>([]);
    const [pscCodes, setPSCCodes] = useState<string[]>([]);
    const [sources, setSources] = useState<{ id: string, name: string }[]>([]);
    const [setAsideCodes, setSetAsideCodes] = useState<{ id: string, name: string }[]>([]);
    const [noticeTypes, setNoticeTypes] = useState<{ id: string, name: string }[]>([]);
    const [naicsCodeOptions, setNAICSCodeOptions] = useState<{ label: string, value: string }[]>([]);
    const [buyerOptions, setBuyerOptions] = useState<{ name: string }[]>([]);
    const [buyerExcludeOptions, setBuyerExcludeOptions] = useState<{ name: string }[]>([]);
    const [pscCodeOptions, setPSCCodeOptions] = useState<{ label: string, value: string }[]>([]);
    const [form] = Form.useForm()
    const [, forceUpdate] = useState({})
    const [tenders, setTenders] = useState<Tender[]>([])
    const [loadingTender, setLoadingTender] = useState<boolean>(true)
    const { message } = App.useApp();

    useEffect(() => {
        // To disable submit button at the beginning.
        forceUpdate({})
        initialAPI().then(response => response)
    }, []);

    const initialAPI = async () => {
        const noticeTypes = await getNoticeTypes() || [];
        setNoticeTypes(noticeTypes);
        const setAsideCodes = await getSetAsideCodes() || [];
        setSetAsideCodes(setAsideCodes);
        const sources = await getSources() || [];
        setSources(sources);
        await getClassifications(
            noticeTypes,
            setAsideCodes
        )
    }

    const getSources = () => axios.post(TENDER_SOURCE_PAGINATE, {}, { params: { page: 0, size: 100 } }).then((response: { data: {
            items: { id: string, name: string }[],
            total: number,
        }}) => {
        const { data: { items: sources, total} } = response
        return sources;
    }).catch((error: AxiosError) => {
        const { response: { data } } = error;
        // @ts-ignore
        message.error(MessageLanguage[data.message]||data.message).then(response => response )
    })

    const getSetAsideCodes = () => axios.post(TENDER_SET_ASIDE_CODE_PAGINATE, {}, { params: { page: 0, size: 100 } }).then((response: { data: {
            items: { id: string, name: string }[],
            total: number,
        }}) => {
        const { data: { items: setAsideCodes, total} } = response
        return setAsideCodes;
    }).catch((error: AxiosError) => {
        const { response: { data } } = error;
        // @ts-ignore
        message.error(MessageLanguage[data.message]||data.message).then(response => response )
    })

    const getNoticeTypes = () => axios.post(TENDER_NOTICE_TYPE_PAGINATE, {}, { params: { page: 0, size: 100 } }).then((response: { data: {
            items: { id: string, name: string }[],
            total: number,
        }}) => {
        const { data: { items: noticeTypes, total} } = response
        return noticeTypes;
    }).catch((error: AxiosError) => {
        const { response: { data } } = error;
        // @ts-ignore
        message.error(MessageLanguage[data.message]||data.message).then(response => response )
    })

    const getClassifications = (
        noticeTypes: { id: string, name: string }[],
        setAsideCodes: { id: string, name: string }[],
    ) => axios.get(USER_MANAGEMENT_PROFILE_GET_CLASSIFICATION).then((response: { data: ClassificationsForm }) => {
        const { data: { naicsCodes = [], pscCodes = [], setAsideCodes: responseSetAsideCodes = []} } = response
        // @ts-ignore
        const setAside: string[] = _.without(responseSetAsideCodes.map((setAsideCode: string) => _.findLast(setAsideCodes, { name: setAsideCode })?.id), undefined);
        setNAICSCodes(naicsCodes);
        setPSCCodes(pscCodes);
        form.setFieldValue('setAside', setAside);
        getTender({
            naicsCodes,
            pscCodes,
            setAside
        },
            sources,
            noticeTypes,
            setAsideCodes
        ).then(response => response)
    }).catch((error: AxiosError) => {
        getTender({
            naicsCodes,
            pscCodes,
        },
            sources,
            noticeTypes,
            setAsideCodes,
            ).then(response => response)
    })

    const getTender = async (
        filter: Filter,
        sources: { id: string, name: string }[],
        noticeTypes: { id: string, name: string }[],
        setAsideCodes: { id: string, name: string }[],
        params = {
        page: 1,
        pageSize: pagination.pageSize,
    }) => {
        const body: Filter = {}
        if (filter.titleKeyword) body.titleKeyword = filter.titleKeyword
        if (filter.source) body.source = _.findLast(sources, { id: filter.source })?.name
        if (filter.active) body.status = 'active'
        if (filter.inactive) body.status = 'inactive'
        if (filter.dueDate && !_.isEmpty(filter.dueDate)) body.dueDate = [dayjs(filter.dueDate[0]).format('YYYY-MM-DD'), dayjs(filter.dueDate[1]).format('YYYY-MM-DD')]
        if (filter.postedDate && !_.isEmpty(filter.postedDate)) body.publishDate = [dayjs(filter.postedDate[0]).format('YYYY-MM-DD'), dayjs(filter.postedDate[1]).format('YYYY-MM-DD')]
        if (filter.solicitationNumber) body.noticeId = filter.solicitationNumber
        if (filter.buyer) body.agency = filter.buyer
        if (filter.buyerExclude) body.agencyExclude = filter.buyerExclude
        if (!_.isEmpty(filter.naicsCodes)) body.naicsCode = filter.naicsCodes
        if (!_.isEmpty(filter.pscCodes)) body.pscCode = filter.pscCodes
        // @ts-ignore
        if (filter.noticeType && !_.isEmpty(filter.noticeType)) body.noticeType = filter.noticeType.map((noticeType: string) => _.findLast(noticeTypes, { id: noticeType })?.name)
        // @ts-ignore
        if (filter.setAside && !_.isEmpty(filter.setAside)) body.setAside = filter.setAside.map((setAside: string) => {
            const name = _.findLast(setAsideCodes, { id: setAside })?.name;
            return (name === 'Without Set Aside Code') ? 'null' : name;
        })

        setFilter(filter)
        return axios.post(TENDER_TENDER_PAGINATE, body, { params: { page: params.page - 1, size: params.pageSize } }).then((response: { data: {
                items: Tender[],
                total: number,
            }}) => {
            const { data: { items: tenders, total} } = response
            setTenders(tenders)
            setPagination({
                ...params,
                total,
            })
            setLoadingTender(false)
        }).catch((error: AxiosError) => {
            const { response: { data } } = error;
            setTenders([])
            setPagination({
                page: 1,
                pageSize: 6,
                total: 0,
            })
            setLoadingTender(false)
            // @ts-ignore
            message.error(MessageLanguage[data.message]||data.message).then(response => response )
        })
    }

    const onFinish = (async (filter: Filter) => {
        setLoadingTender(true)
        setSearch('')
        await getTender({
            ...filter,
            naicsCodes,
            pscCodes,
        },
            sources,
            noticeTypes,
            setAsideCodes,
            )
    })

    return (
        <div className={'opportunities'}>
            <Helmet>
                <title>{title} - {Opportunities.title}</title>
            </Helmet>
            <Row>
                <Col xs={24} sm={24} lg={24} xl={showFilter ? 20 : 24}>
                    <div className={'search'}>
                        <Space direction={'vertical'} size={'large'} className={'search-form'}>
                            <Input placeholder={Opportunities.Search.searchPlaceholder} size="large" value={search} onChange={({ target: { value }}) => setSearch(value)} prefix={
                                <Button
                                    loading={loadingTender}
                                    type={'text'}
                                    icon={<FaSearch size={26} />}
                                    onClick={async () => {
                                        setLoadingTender(true)
                                        setPSCCodeOptions([])
                                        setBuyerOptions([])
                                        setBuyerExcludeOptions([])
                                        setNAICSCodeOptions([])
                                        setNAICSCodes([])
                                        setPSCCodes([])
                                        form.resetFields()
                                        await getTender({
                                            titleKeyword: search,
                                            naicsCodes: [],
                                            pscCodes: [],
                                        },
                                            sources,
                                            noticeTypes,
                                            setAsideCodes,
                                            )
                                    }}
                                />
                            } />
                            <Space className={'search-button'}>
                                <Button type="primary"><Space><span>{Opportunities.Search.createSchedule}</span><FaArrowCircleRight size={15} /></Space></Button>
                                <Button type="primary" onClick={() => setShowFilter(true)}><Space><span>{Opportunities.Search.filter}</span></Space></Button>
                            </Space>
                        </Space>
                        {
                            !_.isEmpty(tenders) ? (
                                <>
                                    <Row gutter={20} className={'search-result'}>
                                        {
                                            tenders.map((tender: Tender) => {
                                                const keywords = tender.keywords ? Array.isArray(tender.keywords) ? tender.keywords : tender.keywords.split(',') : [];
                                                return (
                                                    <Col xs={24} sm={24} lg={24} xl={12}>
                                                        <Card title={(
                                                            <Space direction={"vertical"} size={25}>
                                                                <Typography.Link
                                                                    href={tender.link}
                                                                    target={'_blank'}
                                                                    ellipsis={true}
                                                                    className={'title'}
                                                                >{tender.title}</Typography.Link>
                                                                <Space size={10}>
                                                                    <Tag color="#E8ECEF">Due: {tender.dueDate && dayjs(tender.dueDate).tz(process.env.REACT_APP_TZ).format('MMMM D, YYYY')}</Tag>
                                                                    <Tag color="#E8ECEF">PVB: {PVB[(Math.floor(Math.random() * PVB.length))]}</Tag>
                                                                    <Tag color="#E8ECEF">PTS: {PTS[(Math.floor(Math.random() * PTS.length))]}</Tag>
                                                                </Space>
                                                                <Typography.Paragraph ellipsis={{rows:1, suffix: ""}}>{tender.description}</Typography.Paragraph>
                                                            </Space>
                                                        )}>
                                                            <Space direction={"vertical"} size={20} style={{width:'100%'}}>
                                                                <Space className={'search-tag'} key={'tag'}>
                                                                    <Typography.Paragraph ellipsis={{rows: 2, suffix: ""}}>
                                                                        { keywords.map((keyword: string) => (keyword ? <Tag color="#E8ECEF">{keyword.toString().trim()}</Tag> : null)) }
                                                                    </Typography.Paragraph>
                                                                </Space>
                                                                <Space className={'search-button'} key={'button'}>
                                                                    <Button type="link" className={'link-button'}><Space><span>{Opportunities.Search.askAI}</span><TiArrowForwardOutline size={20} /></Space></Button>
                                                                    <Button type={'primary'}><Space><span>{Opportunities.Search.selectContract}</span></Space></Button>
                                                                </Space>
                                                            </Space>
                                                        </Card>
                                                    </Col>
                                                )
                                            })
                                        }
                                    </Row>
                                    <Flex justify={'center'}>
                                        <Pagination
                                            defaultCurrent={pagination.page}
                                            current={pagination.page}
                                            pageSize={pagination.pageSize}
                                            showSizeChanger={false}
                                            total={pagination.total}
                                            itemRender={ (_, type, originalElement) => {
                                                if (type === 'prev') {
                                                    return <a>{Share.prev}</a>;
                                                }
                                                if (type === 'next') {
                                                    return <a>{Share.next}</a>;
                                                }
                                                return originalElement;
                                            }}
                                            onChange={async (page, pageSize) => {
                                                setLoadingTender(true)
                                                await getTender(filter,
                                                    sources,
                                                    noticeTypes,
                                                    setAsideCodes,
                                                    {
                                                    page,
                                                    pageSize,
                                                })
                                            }}
                                        />
                                    </Flex>
                                </>
                            ) : <Empty description={Share.noResults} />
                        }
                    </div>
                </Col>
                { showFilter && (
                    <Col xs={24} sm={24} lg={24} xl={4}>
                        <div className={'filter'}>
                            <div className={'title'}>
                                <Flex justify={'space-between'}>
                                    <span>{Opportunities.Filter.title}</span>
                                    <FaTimes size={18} onClick={() => setShowFilter(false)} />
                                </Flex>
                            </div>
                            <Space direction={'vertical'} className={'filter-item'} size={20}>
                                <Form layout="vertical" form={form} name="filter-opportunities" onFinish={onFinish}>
                                    <Form.Item
                                        label={Opportunities.Filter.source}
                                        name="source"
                                    >
                                        <Select
                                            allowClear
                                            showSearch
                                            size={'large'}
                                            placeholder={Share.pleaseSelect}
                                            optionFilterProp="label"
                                            options={sources.map(({ id, name }) => ({ label: name, value: id }))}
                                        />
                                    </Form.Item>
                                    <Form.Item
                                        name="active"
                                        valuePropName="checked"
                                        dependencies={['inactive']}
                                        initialValue={false}
                                        style={{marginBottom:5}}
                                    >
                                        <Checkbox onChange={({ target: { checked} }) => checked && form.getFieldValue('inactive') && form.setFieldValue('inactive', false)}>{Opportunities.Filter.activeOnly}</Checkbox>
                                    </Form.Item>
                                    <Form.Item
                                        name="inactive"
                                        valuePropName="checked"
                                        dependencies={['active']}
                                        initialValue={false}
                                    >
                                        <Checkbox onChange={({ target: { checked} }) => checked && form.getFieldValue('active') && form.setFieldValue('active', false)}>{Opportunities.Filter.inactiveOnly}</Checkbox>
                                    </Form.Item>
                                    <Form.Item
                                        label={Opportunities.Filter.dueDate}
                                        name="dueDate"
                                    >
                                        <DatePicker.RangePicker
                                            allowClear={true}
                                            size={'large'}
                                            format="YYYY-MM-DD"
                                        />
                                    </Form.Item>
                                    <Form.Item
                                        label={Opportunities.Filter.postedDate}
                                        name="postedDate"
                                    >
                                        <DatePicker.RangePicker
                                            allowClear={true}
                                            size={'large'}
                                            format="YYYY-MM-DD"
                                        />
                                    </Form.Item>
                                    <Form.Item
                                        label={Opportunities.Filter.solicitationNumber}
                                        name="solicitationNumber"
                                    >
                                        <Input
                                            placeholder={Opportunities.Filter.solicitationNumberPlaceholder}
                                            size="large"
                                        />
                                    </Form.Item>
                                    <Form.Item
                                        label={Opportunities.Filter.buyer}
                                        name="buyer"
                                    >
                                        <AutoComplete
                                            placeholder={Opportunities.Filter.buyerPlaceholder}
                                            size="large"
                                            options={buyerOptions.map(({ name }) => ({ label: (
                                                    <Space direction={'vertical'} size={0}>
                                                        <Typography.Title level={3}>{name}</Typography.Title>
                                                    </Space>
                                                ), value: name }))}
                                            onSearch={(name) => axios.post(TENDER_BUYER_PAGINATE, { name }, { params: { page: 0, size: 10, loading: false } }).then((response: { data: { items: { name: string }[], total: number } }) => {
                                                const { data: { items, total } } = response
                                                if (name) setBuyerOptions(items.map(({ name}) => ({ name })))
                                                else setBuyerOptions([])
                                            }).catch( (error: {}) => setBuyerOptions([]) )}
                                        />
                                    </Form.Item>
                                    <Form.Item
                                        label={Opportunities.Filter.buyerExclude}
                                        name="buyerExclude"
                                    >
                                        <AutoComplete
                                            placeholder={Opportunities.Filter.buyerPlaceholder}
                                            size="large"
                                            options={buyerExcludeOptions.map(({ name }) => ({ label: (
                                                    <Space direction={'vertical'} size={0}>
                                                        <Typography.Title level={3}>{name}</Typography.Title>
                                                    </Space>
                                                ), value: name }))}
                                            onSearch={(name) => axios.post(TENDER_BUYER_PAGINATE, { name }, { params: { page: 0, size: 10, loading: false } }).then((response: { data: { items: { name: string }[], total: number } }) => {
                                                const { data: { items, total } } = response
                                                if (name) setBuyerExcludeOptions(items.map(({ name }) => ({ name })))
                                                else setBuyerExcludeOptions([])
                                            }).catch( (error: {}) => setBuyerExcludeOptions([]) )}
                                        />
                                    </Form.Item>
                                    <Form.Item
                                        label={Opportunities.Filter.naicsCode}
                                        name="naicsCode"
                                        rules={[
                                            { pattern: isNumber, message: `${Opportunities.Filter.naicsCode} ${Validation.number}` },
                                        ]}
                                        extra={!_.isEmpty(naicsCodes) && (
                                            <div className={'tags'}>
                                                {naicsCodes.map((naicsCode: string) => <Tag key={naicsCode} color="#4BAB71" closable onClose={(event: MouseEvent) => {
                                                    const naicsCodesCopy = [...naicsCodes]
                                                    naicsCodesCopy.splice(naicsCodesCopy.indexOf(naicsCode), 1)
                                                    setNAICSCodes(naicsCodesCopy)
                                                }}>{naicsCode}</Tag>)}
                                            </div>
                                        )}
                                    >
                                        <AutoComplete
                                            placeholder={Opportunities.Filter.naicsCodePlaceholder}
                                            size="large"
                                            options={naicsCodeOptions}
                                            onSearch={(code) => axios.post(TENDER_NAICS_CODE_PAGINATE, { code }, { params: { page: 0, size: 10, loading: false } }).then((response: { data: { items: { code: string, title: string }[], total: number } }) => {
                                                const { data: { items, total } } = response
                                                if (code) setNAICSCodeOptions(items.map(({ code, title }, key) => ({ label: `${code} - ${title}`, value: code, key })))
                                                else setNAICSCodeOptions([])
                                            }).catch( (error: {}) => setNAICSCodeOptions([]) )}
                                            onBlur={(event: any) => {
                                                const naicsCode = event?.target?.value;
                                                if (naicsCode && naicsCode.match(isNumber)) {
                                                    setNAICSCodes(_.uniq([...naicsCodes, naicsCode]));
                                                    form.resetFields(['naicsCode']);
                                                }
                                            }}
                                        />
                                    </Form.Item>
                                    <Form.Item
                                        label={Opportunities.Filter.pscCode}
                                        name="pscCode"
                                        extra={!_.isEmpty(pscCodes) && (
                                            <div className={'tags'}>
                                                {pscCodes.map((pscCode: string) => <Tag key={pscCode} color="#4BAB71" closable onClose={(event: MouseEvent) => {
                                                    const pscCodesCopy = [...pscCodes]
                                                    pscCodesCopy.splice(pscCodesCopy.indexOf(pscCode), 1)
                                                    setPSCCodes(pscCodesCopy)
                                                }}>{pscCode}</Tag>)}
                                            </div>
                                        )}
                                    >
                                        <AutoComplete
                                            placeholder={Opportunities.Filter.pscCodePlaceholder}
                                            size={'large'}
                                            options={pscCodeOptions}
                                            onSearch={(code) => axios.post(TENDER_PSC_CODE_PAGINATE, { code }, { params: { page: 0, size: 10, loading: false } }).then((response: { data: { items: { code: string, title: string }[], total: number } }) => {
                                                const { data: { items, total } } = response
                                                if (code) setPSCCodeOptions(items.map(({ code, title }, key) => ({ label: `${code} - ${title}`, value: code, key })))
                                                else setPSCCodeOptions([])
                                            }).catch( (error: {}) => setPSCCodeOptions([]) )}
                                            onBlur={(event: any) => {
                                                const pscCode = event?.target?.value;
                                                if (pscCode) {
                                                    setPSCCodes(_.uniq([...pscCodes, pscCode]));
                                                    form.resetFields(['pscCode']);
                                                }
                                            }}
                                        />
                                    </Form.Item>
                                    <Form.Item
                                        label={Opportunities.Filter.noticeType}
                                        name="noticeType"
                                    >
                                        <Select
                                            allowClear
                                            showSearch
                                            mode="multiple"
                                            size={'large'}
                                            placeholder={Opportunities.Filter.noticeTypePlaceholder}
                                            optionFilterProp="label"
                                            options={noticeTypes.map(({ id, name }) => ({ label: name, value: id }))}
                                        />
                                    </Form.Item>
                                    <Form.Item
                                        label={Opportunities.Filter.setAsideCode}
                                        name="setAside"
                                    >
                                        <Select
                                            allowClear
                                            showSearch
                                            mode="multiple"
                                            size={'large'}
                                            placeholder={Opportunities.Filter.setAsideCodePlaceholder}
                                            optionFilterProp="label"
                                            options={setAsideCodes.map(({ id, name }) => ({ label: name, value: id }))}
                                        />
                                    </Form.Item>
                                    <Form.Item shouldUpdate>
                                        {() => (
                                            <Button
                                                htmlType="submit"
                                                type="primary"
                                                block={true}
                                                loading={loadingTender}
                                            >
                                                {Opportunities.Filter.applyFilter}
                                            </Button>
                                        )}
                                    </Form.Item>
                                    <Form.Item shouldUpdate>
                                        {() => (
                                            <Button
                                                type="link"
                                                block={true}
                                                loading={loadingTender}
                                                className={'link-button'}
                                                onClick={async () => {
                                                    setLoadingTender(true)
                                                    setSearch('')
                                                    setPSCCodeOptions([])
                                                    setBuyerOptions([])
                                                    setBuyerExcludeOptions([])
                                                    setNAICSCodeOptions([])
                                                    setNAICSCodes([])
                                                    setPSCCodes([])
                                                    form.resetFields()
                                                    await getTender({
                                                            naicsCodes: [],
                                                            pscCodes: [],
                                                        },
                                                        sources,
                                                        noticeTypes,
                                                        setAsideCodes,
                                                    )
                                                }}
                                            >
                                                {Opportunities.Filter.clearFilter}
                                            </Button>
                                        )}
                                    </Form.Item>
                                </Form>
                            </Space>
                        </div>
                    </Col>
                )
                }
            </Row>
        </div>
    )
}
