import React, { useContext, useEffect, useState } from 'react'
import {Space, Typography, App, Upload, Button, Modal, Dropdown, Spin, Form} from 'antd'
import type { GetProp, UploadFile, UploadProps } from 'antd';
import { Helmet } from "react-helmet"
import { FaTrashAlt, FaCloudUploadAlt, FaRegFileAlt, FaEllipsisV, FaDownload } from "react-icons/fa";
import { API, Enum } from "../../resources/constants";
import language from "../../resources/languages/en_US"

// Interfaces
import { AIFile } from "../../interfaces/pages/profile"
import { AxiosError } from "../../interfaces/axios"
type FileType = Parameters<GetProp<UploadProps, 'beforeUpload'>>[0]

// Contexts
import GlobalContext from "../../contexts/global";
import AxiosContext from "../../contexts/axios";

// Components
import NAICSSuggestion from './naics-suggestion'

const { App: { title }, Pages: { Profile: { AIFiles: AIFilesLanguage }  }, Share, Enum: EnumLanguage, Validation, Message: MessageLanguage } = language
const { USER_MANAGEMENT_PROFILE_AI_FILE_ALL, USER_MANAGEMENT_PROFILE_AI_FILE_REMOVE, USER_MANAGEMENT_PROFILE_AI_FILE_ADD, DOWNLOAD } = API
const { Message } = Enum
const baseURL = process.env.NODE_ENV === 'development' ? process.env.REACT_APP_DEVELOPMENT_API_URL : process.env.REACT_APP_PRODUCTION_API_URL

export default function AiFiles() {

    const [aiFileId, setAIFileId] = useState<string|null>(null)
    const [aiFiles, setAIFiles] = useState<AIFile[]>([])
    const [openModal, setOpenModal] = useState(false)
    const [uploading, setUploading] = useState(false)
    const [form] = Form.useForm()
    const { accessToken} = useContext(GlobalContext)
    const { axios } = useContext(AxiosContext)
    const { message, modal } = App.useApp()

    useEffect(() => {
        initialAPI().then(response => response)
    }, []);

    const initialAPI = async () => {
        await getAIFiles()
    }

    const getAIFiles = async () => axios.get(USER_MANAGEMENT_PROFILE_AI_FILE_ALL).then((response: { data: AIFile[] }) => {
        const { data: aiFiles } = response
        setAIFiles(aiFiles)
    }, (error: AxiosError) => {
        const { response: { data } } = error;
        // @ts-ignore
        message.error(MessageLanguage[data.message]).then(response => response)
    })

    const beforeUpload = (file: FileType) => {
        const isTxtOrPdfOrDoc = file.type === 'text/plain' || file.type === 'application/pdf' || file.type === 'application/msword' || file.type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document';
        if (!isTxtOrPdfOrDoc) {
            // @ts-ignore
            message.error(AIFilesLanguage.aiFileFormat).then(response => response );
        }
        const isLt10M = file.size / 1024 / 1024 < 10;
        if (!isLt10M) {
            // @ts-ignore
            message.error(AIFilesLanguage.aiFileSize).then(response => response );
        }
        return isTxtOrPdfOrDoc && isLt10M;
    };

    const onChange = async (info: { file: UploadFile, fileList: UploadFile[] }) => {
        const { file, fileList } = info
        const { status } = file;
        if (status === 'uploading') {
            setUploading(true)
        } else if (status === 'done') {
            const { response: aiFile } = file;
            setAIFileId(aiFile.id);
            setOpenModal(true)
            await getAIFiles()
            setUploading(false)
            message.success(MessageLanguage[Message.UPLOAD_SUCCESS]).then(response => response)
        } else if (status === 'error') {
            const { response } = file;
            setUploading(false)
            // @ts-ignore
            message.error(response?.message ? response.message : MessageLanguage[Message.UPLOAD_ERROR]).then(response => response)
        }
    };

    return (
        <div className={'ai-files'}>
            <Helmet>
                <title>{title} - {AIFilesLanguage.title}</title>
            </Helmet>
            <Modal
                className={'naics-suggestion-modal'}
                width={650}
                destroyOnClose={true}
                title={AIFilesLanguage.naicsCodeTitle}
                open={openModal}
                cancelButtonProps={{
                    style: {display:'none'},
                }}
                okText={AIFilesLanguage.saveChange}
                okButtonProps={{
                    block: true,
                    type: 'default',
                }}
                onOk={() => form.submit()}
                onCancel={() => {
                    form.resetFields()
                    setAIFileId(null)
                    setOpenModal(false)
                }}
            >
                { aiFileId && <NAICSSuggestion Form={Form} form={form} aiFileId={aiFileId} setOpenModal={setOpenModal} /> }
            </Modal>
            <Space direction="vertical" size={50} style={{width:'100%'}}>
                <Typography.Title level={1}>{AIFilesLanguage.title}</Typography.Title>
                <Spin spinning={uploading} tip={Share.loading}>
                    <div className={'upload-wrapper'}>
                        <Upload.Dragger
                            action={baseURL + USER_MANAGEMENT_PROFILE_AI_FILE_ADD}
                            headers={{ Authorization: `Bearer ${accessToken}` }}
                            multiple={false}
                            showUploadList={false}
                            disabled={uploading}
                            beforeUpload={beforeUpload}
                            onChange={onChange}
                        >
                            <div className="ant-upload-drag-icon"><FaCloudUploadAlt size={30} /></div>
                            <Typography.Title level={3} className="ant-upload-text">{AIFilesLanguage.upload}</Typography.Title>
                            <Typography.Title level={4} className="ant-upload-hint">{AIFilesLanguage.uploadDescription}</Typography.Title>
                        </Upload.Dragger>
                    </div>
                </Spin>
                <Typography.Title level={2}>{AIFilesLanguage.uploadRecently}</Typography.Title>
                <Space direction="vertical" size={20} style={{width:'100%'}}>
                    {
                        aiFiles.map(({ id, file: { id: fileId, originalName, size } }: AIFile, key) => (
                            <div key={key} className={'ai-file'}>
                                <Typography.Text>{originalName}</Typography.Text>
                                <Typography.Text>{size>1024*1024 ? `${(size/1024/1024).toFixed(1)} MB` : `${(size/1024).toFixed(0)} KB`}</Typography.Text>
                                <Button href={baseURL + DOWNLOAD + fileId + `?token=${accessToken}`} type="link" className={'link-button'}><FaDownload size={15} /><Space><span>{Share.download}</span></Space></Button>
                                <Dropdown menu={{ items: [
                                        {
                                            key: 'view',
                                            label:<Space onClick={() => {
                                                setAIFileId(id)
                                                setOpenModal(true)
                                            }}><FaRegFileAlt size={15} /><span>{AIFilesLanguage.naicsCodeTitle}</span></Space>,
                                        },
                                        {
                                            key: 'delete',
                                            label: <Space onClick={() => modal.confirm({
                                                className: 'ant-modal-ai-files',
                                                title: Share.deleteConfirmMemberTitle,
                                                okText: Share.yes,
                                                okType: 'danger',
                                                cancelText: Share.no,
                                                onOk() {
                                                    axios.delete(USER_MANAGEMENT_PROFILE_AI_FILE_REMOVE + id).then(async () => {
                                                        await getAIFiles()
                                                        message.success(MessageLanguage[Message.DELETE_MEMBER]).then(response => response)
                                                    }).catch((error: AxiosError) => {
                                                        const { response: { data } } = error;
                                                        // @ts-ignore
                                                        message.error(MessageLanguage[data.message]).then(response => response )
                                                    })
                                                }
                                            })}><FaTrashAlt size={14} /><span>{Share.delete}</span></Space>,
                                        },
                                    ] }} placement="bottomLeft"><a><FaEllipsisV /></a></Dropdown>
                            </div>
                        ))
                    }
                </Space>
            </Space>
        </div>
    )
}
