import React, {MouseEvent, useContext, useEffect, useState} from "react";
import {Row, Col, Typography, App, Space, Tag, AutoComplete} from 'antd';
import _ from "lodash";
import { API, Enum, Regex } from "../../resources/constants";
import language from "../../resources/languages/en_US";

// Interfaces
import { AIFile, ClassificationsForm } from "../../interfaces/pages/profile"
import { AxiosError } from "../../interfaces/axios"

// Contexts
import AxiosContext from "../../contexts/axios";

const { Pages: { Profile: { AIFiles: AIFilesLanguage }  }, Share, Enum: EnumLanguage, Validation, Message: MessageLanguage } = language
const { USER_MANAGEMENT_PROFILE_GET_AI_FILE, USER_MANAGEMENT_PROFILE_GET_CLASSIFICATION, USER_MANAGEMENT_PROFILE_SET_CLASSIFICATION, TENDER_NAICS_CODE_PAGINATE} = API
const { Message } = Enum
const { isNumber } = Regex

export default function NAICSSuggestion(props: { Form: any, form: any, aiFileId: string, setOpenModal: (openModal: boolean) => void }) {
    const { Form, form, aiFileId, setOpenModal } = props;

    const [naicsCodes, setNAICSCodes] = useState<string[]>([])
    const [naicsCodeOptions, setNAICSCodeOptions] = useState<{ label: string, value: string }[]>([])
    const [aiFile, setAIFile] = useState<AIFile|null>(null)
    const { axios } = useContext(AxiosContext)
    const { message } = App.useApp()

    useEffect(() => {
        initialAPI().then(response => response)
    }, []);

    const initialAPI = async () => {
        await getAIFile()
        await getClassifications()
    }

    const getAIFile = () => axios.get(USER_MANAGEMENT_PROFILE_GET_AI_FILE + aiFileId).then((response: { data: AIFile }) => {
        const { data: aiFile } = response
        setAIFile(aiFile)
    }, (error: AxiosError) => {
        const { response: { data } } = error;
        // @ts-ignore
        message.error(MessageLanguage[data.message]).then(response => response)
    })

    const getClassifications = async () => axios.get(USER_MANAGEMENT_PROFILE_GET_CLASSIFICATION).then((response: { data: ClassificationsForm }) => {
        const { data: { naicsCodes = [] } } = response
        form.resetFields();
        setNAICSCodeOptions([])
        setNAICSCodes(naicsCodes);
    }).catch( (error: {}) => error )

    const onFinish = (values: ClassificationsForm) => {
        const body: ClassificationsForm = {};
        if (!_.isEmpty(naicsCodes)) body.naicsCodes = naicsCodes
        axios.post(USER_MANAGEMENT_PROFILE_SET_CLASSIFICATION, body).then(async (response: {}) => {
            await getClassifications()
            message.success(MessageLanguage[Message.CHANGE_SUCCESS]).then(response => response)
        }, (error: AxiosError) => {
            const { response: { data } } = error;
            // @ts-ignore
            message.error(MessageLanguage[data.message]).then(response => response)
        })
    };

    return (
        <Space direction="vertical" style={{width:'100%'}}>
            <Form layout={'vertical'} form={form} name="naics-suggestion" autoComplete="off" onFinish={onFinish}>
                <Form.Item
                    label={AIFilesLanguage.naicsCode}
                    name="naicsCode"
                    rules={[
                        { required: !naicsCodes.length, message: `${AIFilesLanguage.naicsCode} ${Validation.required}` },
                        { pattern: isNumber, message: `${AIFilesLanguage.naicsCode} ${Validation.number}` },
                    ]}
                >
                    <AutoComplete
                        size="large"
                        options={naicsCodeOptions}
                        onSearch={(code) => axios.post(TENDER_NAICS_CODE_PAGINATE, { code }, { params: { page: 0, size: 10, loading: false } }).then((response: { data: { items: { code: string, title: string }[], total: number } }) => {
                            const { data: { items, total } } = response
                            if (code) setNAICSCodeOptions(items.map(({ code, title }, key) => ({ label: `${code} - ${title}`, value: code, key })))
                            else setNAICSCodeOptions([])
                        }).catch( (error: {}) => setNAICSCodeOptions([]) )}
                        onBlur={(event: any) => {
                            const naicsCode = event?.target?.value;
                            if (naicsCode && naicsCode.match(isNumber)) {
                                setNAICSCodes(_.uniq([...naicsCodes, naicsCode]));
                                form.resetFields(['naicsCode']);
                            }
                        }}
                    />
                </Form.Item>
            </Form>
            <Row gutter={20}>
                <Col xs={24} sm={24} lg={24} xl={12}>
                    <Space direction={'vertical'}>
                        <Typography.Title level={2}>{AIFilesLanguage.naicsCodeSelected}</Typography.Title>
                        <div className={'tags'}>
                            {naicsCodes.map((naicsCode: string) => <Tag key={naicsCode} color="#4BAB71" closable onClose={(event: MouseEvent) => {
                                const naicsCodesCopy = [...naicsCodes]
                                naicsCodesCopy.splice(naicsCodesCopy.indexOf(naicsCode), 1)
                                setNAICSCodes(naicsCodesCopy)
                            }}>{naicsCode}</Tag>)}
                        </div>
                    </Space>
                </Col>
                <Col xs={24} sm={24} lg={24} xl={12}>
                    <Space direction={'vertical'}>
                        <Typography.Title level={2}>{AIFilesLanguage.naicsCodeSuggested}</Typography.Title>
                        <div className={'tags'}>
                            { aiFile?.ai?.naicsCode && <Tag color="#00899B">{aiFile?.ai?.naicsCode}</Tag> }
                        </div>
                    </Space>
                </Col>
            </Row>
        </Space>
    )
}
