import React, { createContext, Dispatch  } from 'react';

export default createContext<{ pendingRequests: number, axios: {get: any, post: any, delete: any, put: any, patch: any, interceptors: any}, dispatch: Dispatch<{ type: string, payload: object }> }>({
  pendingRequests: 0,
  axios: {
    get: () => {},
    post: () => {},
    delete: () => {},
    put: () => {},
    patch: () => {},
    interceptors: {},
  },
  dispatch: () => {}
})
