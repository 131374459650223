import React, { useState, useContext } from 'react'
import { useNavigate } from "react-router-dom"
import { isMobileOnly, isDesktop } from "react-device-detect"
import Cookies from "js-cookie";
import { Button, theme, App, Affix, Avatar, Space, Typography, Tooltip } from 'antd'
import { FaRegUser, FaSignOutAlt } from "react-icons/fa";
import { ActionType, Enum, API } from "../../resources/constants";
import language from "../../resources/languages/en_US";

// Contexts
import GlobalContext from '../../contexts/global'

const { Message: MessageLanguage, Layouts: { Dashboard } } = language
const { SET_USER, SET_ACCESS_TOKEN } = ActionType
const { DOWNLOAD } = API
const { Message } = Enum
const baseURL = process.env.NODE_ENV === 'development' ? process.env.REACT_APP_DEVELOPMENT_API_URL : process.env.REACT_APP_PRODUCTION_API_URL

export default function Index({ collapsed }: { collapsed: boolean }) {

    const { token: { colorBgContainer } } = theme.useToken()
    const { user, accessToken, dispatch } = useContext(GlobalContext)
    const navigate = useNavigate()
    const { message } = App.useApp()

    const signOut = () => {
        Cookies.remove('accessToken')
        dispatch({ type: SET_ACCESS_TOKEN , payload: { accessToken: null }})
        dispatch({ type: SET_USER , payload: { user: null }})
        message.success(MessageLanguage[Message.SIGN_OUT_PROFILE]).then(response => response)
        navigate("/")
    }

    return (
        <div className={'wrapper-user-box'}>
            <Affix offsetBottom={collapsed ? 20 : 122}>
                {
                    (collapsed && !isMobileOnly) ? (
                        <Tooltip placement="right" title={Dashboard.signOut}><Typography.Link><Avatar size={38} icon={<FaSignOutAlt />} onClick={signOut} style={{marginLeft:15}}/></Typography.Link></Tooltip>
                    ) : (
                        <Space direction="vertical" size={20} className={'user-box'}>
                            <Space size={15}>
                                <Avatar size={45} icon={user?.avatar ? <img src={baseURL + DOWNLOAD + user.avatar + `?width=45&height=45&token=${accessToken}`} width={45} /> : <FaRegUser />} />
                                <Space direction="vertical" size={0}>
                                    {user && (
                                        <>
                                            <Typography.Text className={'name'}>{user.firstName} {user.lastName}</Typography.Text>
                                            <Typography.Text className={'email'}>{user.email}</Typography.Text>
                                        </>
                                    )}
                                </Space>
                            </Space>
                            <Button type="link" block className={'link-button'}  onClick={signOut}>{Dashboard.signOut}</Button>
                        </Space>
                    )
                }
            </Affix>
        </div>
    )
}
