import React from 'react'
import { Layout } from 'antd'

import language from "../../resources/languages/en_US";

const { Footer } = Layout
const { Layouts: { Public } } = language

export default function Index() {

    return (
        <Footer>{Public.copyright}</Footer>
    )
}
