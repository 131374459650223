import React, { useContext } from "react";
import _ from "lodash";
import { Navigate, useLocation } from "react-router-dom";

// Contexts
import GlobalContext from "../../contexts/global";
export default function Index(props: { children: JSX.Element, roles?: string[] }) {

    const { children, roles = [] } = props;
    const { user } = useContext(GlobalContext)
    const location = useLocation();

    if (user && (_.isEmpty(roles) || roles.map(role => user.roles.includes(role)).includes(true))) return children;
    else if (user) return <Navigate to="/payment" replace />;
    else return <Navigate to="/sign-in" state={{ from: location }} replace />
}
