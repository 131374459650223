import React, { useContext } from 'react'
import { Space, Typography, Divider, Row, Col } from 'antd'
import { NavLink, Outlet } from 'react-router-dom'
import { Helmet } from "react-helmet";
import { FaUserAlt, FaCube, FaFileAlt, FaUserPlus, FaUpload, FaTrashAlt, FaCreditCard } from "react-icons/fa"
import language from "../../resources/languages/en_US"

const { App: { title }, Pages: { Profile }, Share } = language

// Contexts
import GlobalContext from '../../contexts/global'

export default function Index() {

    const { user} = useContext(GlobalContext)

    return (
        <div className={'profile'}>
            <Helmet>
                <title>{title} - {Profile.title}</title>
            </Helmet>
                <Row className={'content'}>
                        <Col xs={24} sm={24} lg={24} xl={5}>
                                <div className={'menu'}>
                                    <Space direction={'vertical'} className={'part-one'}>
                                        { user && <Typography.Title level={2}>{user.firstName} {user.lastName}</Typography.Title> }
                                        <NavLink to={'/profile/my-account'} className={'link-button block'}><Space><FaUserAlt size={15} /><span>{Profile.myAccount}</span></Space></NavLink>
                                    </Space>
                                        {
                                            ['ADMIN'].map(role => user?.roles.includes(role)).includes(true) && (
                                                <Space direction={'vertical'} className={'part-two'} size={15}>
                                                    <Typography.Title level={2}>{Profile.felixTechnology}</Typography.Title>
                                                    <NavLink to={'/profile/overview'} className={'link-button block'}><Space><FaCube size={15} /><span>{Profile.overview}</span></Space></NavLink>
                                                    <NavLink to={'/profile/classifications'} className={'link-button block'}><Space><FaFileAlt size={15} /><span>{Profile.classifications}</span></Space></NavLink>
                                                    <NavLink to={'/profile/ai-files'} className={'link-button block'}><Space><FaUpload size={15} /><span>{Profile.aiFiles}</span></Space></NavLink>
                                                    <NavLink to={'/profile/members'} className={'link-button block'}><Space><FaUserPlus size={20} /><span>{Profile.members}</span></Space></NavLink>
                                                </Space>
                                            )
                                        }
                                    <Divider />
                                    <Space direction={'vertical'} className={'part-three'} size={15}>
                                        {
                                            ['ADMIN'].map(role => user?.roles.includes(role)).includes(true) && (
                                                <>
                                                    <NavLink to={'/profile/payment-history'} className={'link-button block'}><Space><FaCreditCard size={20} /><span>{Profile.paymentHistory}</span></Space></NavLink>
                                                    <NavLink to={'/profile/delete-account'} className={'link-button block delete-account'}><Space><FaTrashAlt size={15} /><span>{Profile.deleteAccount}</span></Space></NavLink>
                                                </>
                                            )
                                        }
                                    </Space>
                                </div>
                        </Col>
                        <Col xs={24} sm={24} lg={24} xl={19}>
                                <div className={'item-wrapper'}>
                                    <div className={'item'}>
                                        <Outlet />
                                    </div>
                                </div>
                        </Col>
                </Row>
        </div>
    )
}
