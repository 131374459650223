import React, { useState } from 'react'
import { Button, App } from 'antd'
import { useStripe, useElements, PaymentElement } from '@stripe/react-stripe-js'
import { API, Enum } from "../../resources/constants"
import language from "../../resources/languages/en_US"

const { Pages: { Payment: PaymentLanguage}, Share, Validation, Message: MessageLanguage } = language
const { USER_MANAGEMENT_PROFILE_INVOICE_VERIFY } = API;
const { Message } = Enum
const baseURL = process.env.NODE_ENV === 'development' ? process.env.REACT_APP_DEVELOPMENT_API_URL : process.env.REACT_APP_PRODUCTION_API_URL

export default function StripeCheckoutForm(props: { invoice: string, amount: number }) {
    const { invoice, amount } = props;

    const [loading, setLoading] = useState(false)
    const stripe = useStripe()
    const elements = useElements()
    const { message, modal } = App.useApp()

    const handleSubmit = async (event: any) => {
        // We don't want to let default form submission happen here,
        // which would refresh the page.
        event.preventDefault();

        if (!stripe || !elements) {
            // Stripe.js hasn't yet loaded.
            // Make sure to disable form submission until Stripe.js has loaded.
            return;
        }

        const result = await stripe.confirmPayment({
            //`Elements` instance that was used to create the Payment Element
            elements,
            confirmParams: {
                return_url: baseURL + USER_MANAGEMENT_PROFILE_INVOICE_VERIFY + invoice,
            },
        });

        if (result.error) {
            // Show error to your customer (for example, payment details incomplete)
            message.error(result.error.message).then(response => response)
        } else {
            // Your customer will be redirected to your `return_url`. For some payment
            // methods like iDEAL, your customer will be redirected to an intermediate
            // site first to authorize the payment, then redirected to the `return_url`.
        }

        setLoading(false)
    };

    return (
        <form onSubmit={handleSubmit}>
            <PaymentElement/>
            <Button htmlType="submit" block disabled={!invoice} loading={loading} style={{marginTop: 40}} onClick={() => setLoading(true)}>{PaymentLanguage.pay1}{amount}{PaymentLanguage.pay2}</Button>
        </form>
    )
}
