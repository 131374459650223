import React, { useContext, useEffect, useState } from 'react'
import { Space, Typography, Form, App, Row, Col, Input, Button, Flex, Switch, Avatar, Spin, Upload } from 'antd'
import { Helmet } from "react-helmet"
import validator from "validator";
import { FaLock, FaLockOpen, FaCheck, FaRegUser, FaCloudUploadAlt } from "react-icons/fa";
import type { GetProp, UploadFile, UploadProps } from 'antd';
import { ActionType, API, Enum } from "../../resources/constants";
import language from "../../resources/languages/en_US"

// Interfaces
import { MyAccountForm } from "../../interfaces/pages/profile"
import { AxiosError } from "../../interfaces/axios"
type FileType = Parameters<GetProp<UploadProps, 'beforeUpload'>>[0];

// Contexts
import GlobalContext from "../../contexts/global";
import AxiosContext from "../../contexts/axios";

const { App: { title }, Pages: { Profile: { MyAccount: MyAccountLanguage }  }, Share, Validation, Message: MessageLanguage } = language
const { SET_USER } = ActionType
const { USER_MANAGEMENT_PROFILE_UPDATE, USER_MANAGEMENT_PROFILE_MY_ACCOUNT, USER_MANAGEMENT_PROFILE_UPDATE_AVATAR, DOWNLOAD } = API
const { Message } = Enum
const baseURL = process.env.NODE_ENV === 'development' ? process.env.REACT_APP_DEVELOPMENT_API_URL : process.env.REACT_APP_PRODUCTION_API_URL

export default function MyAccount() {

    const [form] = Form.useForm()
    const [, forceUpdate] = useState({})
    const [uploading, setUploading] = useState(false);
    const { user, accessToken, dispatch } = useContext(GlobalContext)
    const { axios } = useContext(AxiosContext)
    const { message, modal } = App.useApp()

    useEffect(() => {
        // To disable submit button at the beginning.
        forceUpdate({});
        form.setFieldsValue({ ...user, avatar: undefined })
    }, []);

    const beforeUpload = (file: FileType) => {
        const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
        if (!isJpgOrPng) {
            // @ts-ignore
            message.error(MyAccountLanguage.avatarFormat).then(response => response );
        }
        const isLt2M = file.size / 1024 / 1024 < 2;
        if (!isLt2M) {
            // @ts-ignore
            message.error(MyAccountLanguage.avatarSize).then(response => response );
        }
        return isJpgOrPng && isLt2M;
    };

    const onChange = async (info: { file: UploadFile, fileList: UploadFile[] }) => {
        const { file, fileList } = info
        const { status } = file;
        if (status === 'uploading') {
            setUploading(true)
        } else if (status === 'done') {
            axios.get(USER_MANAGEMENT_PROFILE_MY_ACCOUNT).then((response: { data: {} }) => {
                const { data: user } = response
                dispatch({ type: SET_USER , payload: { user }})
                setUploading(false)
                message.success(MessageLanguage[Message.UPLOAD_SUCCESS]).then(response => response)
            }).catch( (error: {}) => error )
        } else if (status === 'error') {
            const { response } = file;
            setUploading(false)
            // @ts-ignore
            message.error(MessageLanguage[response.message ? response.message : Message.UPLOAD_ERROR]).then(response => response)
        }
    };

    const onFinish = (values: MyAccountForm) => {
        const { firstName, lastName, email, password, newPassword, otp   } = values
        const body: MyAccountForm = {
            firstName,
            lastName,
            email,
            otp,
        };
        if (password) body.password = password
        if (newPassword) body.newPassword = newPassword
        axios.patch(USER_MANAGEMENT_PROFILE_UPDATE, { ...body }).then((response: {}) => {
            axios.get(USER_MANAGEMENT_PROFILE_MY_ACCOUNT).then((response: { data: {} }) => {
                const { data: user } = response
                dispatch({ type: SET_USER , payload: { user }})
                form.resetFields(['password', 'newPassword', 'confirm'])
                form.setFieldsValue({ ...user, avatar: undefined })
                message.success(MessageLanguage[Message.UPDATE_PROFILE]).then(response => response)
            }).catch( (error: {}) => error )
        }, (error: AxiosError) => {
            const { response: { data } } = error;
            // @ts-ignore
            message.error(MessageLanguage[data.message]).then(response => response)
        })
    };

    return (
        <div className={'my-account'}>
            <Helmet>
                <title>{title} - {MyAccountLanguage.title}</title>
            </Helmet>
            <Space direction="vertical" size={50} style={{width:'100%'}}>
                <Typography.Title level={1}>{MyAccountLanguage.title}</Typography.Title>
                <Form layout={'vertical'} form={form} name="my-account" autoComplete="off" onFinish={onFinish}>
                    <Form.Item
                        label={MyAccountLanguage.avatar}
                        name="avatar"
                    >
                        <Upload
                            action={baseURL + USER_MANAGEMENT_PROFILE_UPDATE_AVATAR}
                            headers={{ Authorization: `Bearer ${accessToken}` }}
                            multiple={false}
                            showUploadList={false}
                            disabled={uploading}
                            beforeUpload={beforeUpload}
                            onChange={onChange}
                        >
                            <Spin spinning={uploading} tip={Share.loading}>
                                <Row gutter={20}>
                                    <Col xs={24} sm={24} lg={24} xl={12} xxl={7}>
                                        <Avatar size={140} icon={user?.avatar ? <img src={baseURL + DOWNLOAD + user.avatar + `?width=140&height=140&token=${accessToken}`} width={140} /> : <FaRegUser size={50} />} />
                                    </Col>
                                    <Col xs={24} sm={24} lg={24} xl={12} xxl={17}>
                                        <Space direction={'vertical'} size={20} style={{width:'100%'}}>
                                            <Button type={'link'} className={'link-button link-button-mobile'} style={{float:'left'}}><Space><span>{MyAccountLanguage.uploadTitle}</span><FaCloudUploadAlt size={20} /></Space></Button>
                                            <Typography.Text>{MyAccountLanguage.uploadDescription}</Typography.Text>
                                        </Space>
                                    </Col>
                                </Row>
                            </Spin>
                        </Upload>
                    </Form.Item>
                    <Typography.Title level={2}>{MyAccountLanguage.information}</Typography.Title>
                    <Row gutter={20}>
                        <Col xs={24} sm={24} lg={24} xl={12} xxl={12}>
                            <Form.Item
                                label={MyAccountLanguage.firstName}
                                name="firstName"
                                rules={[
                                    { required: true, message: `${MyAccountLanguage.firstName} ${Validation.required}` },
                                    { min: 3, message: `${MyAccountLanguage.firstName} ${Validation.min} 3 ${Validation.length}` },
                                    { max: 30, message: `${MyAccountLanguage.firstName} ${Validation.max} 30 ${Validation.length}` },
                                ]}
                            >
                                <Input maxLength={30} />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} lg={24} xl={12} xxl={12}>
                            <Form.Item
                                label={MyAccountLanguage.lastName}
                                name="lastName"
                                rules={[
                                    { required: true, message: `${MyAccountLanguage.lastName} ${Validation.required}` },
                                    { min: 3, message: `${MyAccountLanguage.lastName} ${Validation.min} 3 ${Validation.length}` },
                                    { max: 30, message: `${MyAccountLanguage.lastName} ${Validation.max} 30 ${Validation.length}` },
                                ]}
                            >
                                <Input maxLength={30} />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Form.Item
                        label={MyAccountLanguage.email}
                        name="email"
                        rules={[
                            { required: true, message: `${MyAccountLanguage.email} ${Validation.required}` },
                            ({ getFieldValue }) => ({
                                validator(_, value) {
                                    if (!value || validator.isEmail(value)) return Promise.resolve()
                                    else return Promise.reject(new Error(Validation.invalidEmail))
                                },
                            }),
                        ]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        label={MyAccountLanguage.password}
                        name="password"
                        dependencies={['newPassword']}
                        rules={[
                            ({ getFieldValue }) => ({
                                validator(_, value) {
                                    if (value || !getFieldValue('newPassword')) {
                                        return Promise.resolve();
                                    }
                                    return Promise.reject(new Error(`${MyAccountLanguage.password} ${Validation.required}`));
                                },
                            }),
                        ]}
                    >
                        <Input.Password prefix={<FaLockOpen />} placeholder={MyAccountLanguage.password} />
                    </Form.Item>
                    <Form.Item
                        label={MyAccountLanguage.newPassword}
                        name="newPassword"
                        dependencies={['password']}
                        rules={[
                            { min: 8, message: `${MyAccountLanguage.newPassword} ${Validation.min} 8 ${Validation.length}` },
                            { max: 100, message: `${MyAccountLanguage.newPassword} ${Validation.max} 100 ${Validation.length}` },
                            ({ getFieldValue }) => ({
                                validator(_, value) {
                                    if (value || !getFieldValue('password')) {
                                        return Promise.resolve();
                                    }
                                    return Promise.reject(new Error(`${MyAccountLanguage.newPassword} ${Validation.required}`));
                                },
                            }),
                        ]}
                        extra={<span>{MyAccountLanguage.passwordDescription}</span>}
                    >
                        <Input.Password prefix={<FaLock />} placeholder={MyAccountLanguage.newPassword} />
                    </Form.Item>
                    <Form.Item
                        label={MyAccountLanguage.newPasswordConfirm}
                        name="confirm"
                        dependencies={['newPassword']}
                        rules={[
                            ({ getFieldValue }) => ({
                                validator(_, value) {
                                    if ((!value && !getFieldValue('newPassword')) || getFieldValue('newPassword') === value) {
                                        return Promise.resolve();
                                    }
                                    return Promise.reject(new Error(Validation.passwordConfirm));
                                },
                            }),
                        ]}
                        extra={<span>{MyAccountLanguage.passwordDescription}</span>}
                    >
                        <Input.Password prefix={<FaLock />} placeholder={MyAccountLanguage.newPasswordConfirm} />
                    </Form.Item>
                    {/*<Space align="center">*/}
                    {/*    <Form.Item*/}
                    {/*        label={MyAccountLanguage.otp}*/}
                    {/*        name="otp"*/}
                    {/*        valuePropName="checked"*/}
                    {/*    >*/}
                    {/*        <Switch />*/}
                    {/*    </Form.Item>*/}
                    {/*    <Typography.Text className={'switch'}>{MyAccountLanguage.enableOTP}</Typography.Text>*/}
                    {/*</Space>*/}
                    <Flex gap={'middle'} justify={'end'}>
                        <Form.Item shouldUpdate>
                            {() => (
                                <Button
                                    type="link"
                                    className={'link-button'}
                                    onClick={() => {
                                        form.resetFields(['password', 'newPassword', 'confirm'])
                                        form.setFieldsValue({ ...user, avatar: undefined })
                                    }}
                                >
                                    {MyAccountLanguage.cancel}
                                </Button>
                            )}
                        </Form.Item>
                        <Form.Item shouldUpdate>
                            {() => (
                                <Button
                                    htmlType="submit"
                                >
                                    <Space><span>{MyAccountLanguage.saveChange}</span><FaCheck size={15} /></Space>
                                </Button>
                            )}
                        </Form.Item>
                    </Flex>
                </Form>
            </Space>
        </div>
    )
}
