import  { ActionType } from "./../resources/constants"

const { SET_PENDING_REQUESTS } = ActionType

export default function AxiosReducer(state: any, action: { type: string, payload?: any }) {
  switch (action.type) {
    case SET_PENDING_REQUESTS:
      return setPendingRequests(state,action);
    default:
      return state;
  }
}

const setPendingRequests = (state: any, action: { type: string, payload?: any } ) => {
  const { pendingRequests } = state
  const { plus } = action.payload

  return {
    ...state,
    pendingRequests: plus
      ? pendingRequests + 1
      : pendingRequests > 0
        ? pendingRequests - 1
        : 0,
  }
}
