import React, { useContext, useEffect, useState, MouseEvent } from 'react'
import {Space, Typography, Form, App, Button, AutoComplete, Tag, Flex, Select} from 'antd'
import { Helmet } from "react-helmet"
import _ from "lodash";
import { useNavigate } from 'react-router-dom'
import { FaCheck } from "react-icons/fa";
import { API, Enum, Regex } from "../../resources/constants";
import language from "../../resources/languages/en_US"

// Interfaces
import { ClassificationsForm } from "../../interfaces/pages/profile"
import { AxiosError } from "../../interfaces/axios"

// Contexts
import AxiosContext from "../../contexts/axios";

const { App: { title }, Pages: { Profile: { Classifications: ClassificationsLanguage }  }, Share, Enum: EnumLanguage, Validation, Message: MessageLanguage } = language
const { USER_MANAGEMENT_PROFILE_GET_CLASSIFICATION, USER_MANAGEMENT_PROFILE_SET_CLASSIFICATION, TENDER_NAICS_CODE_PAGINATE, TENDER_PSC_CODE_PAGINATE, TENDER_SET_ASIDE_CODE_PAGINATE } = API
const { Message } = Enum
const { isNumber } = Regex

export default function Classifications() {

    const [naicsCodes, setNAICSCodes] = useState<string[]>([]);
    const [pscCodes, setPSCCodes] = useState<string[]>([]);
    const [naicsCodeOptions, setNAICSCodeOptions] = useState<{ label: string, value: string }[]>([]);
    const [pscCodeOptions, setPSCCodeOptions] = useState<{ label: string, value: string }[]>([]);
    const [setAsideCodes, setSetAsideCodes] = useState<{ id: string, name: string }[]>([]);
    const [form] = Form.useForm()
    const [, forceUpdate] = useState({})
    const { axios } = useContext(AxiosContext)
    const navigate = useNavigate()
    const { message } = App.useApp()

    useEffect(() => {
        // To disable submit button at the beginning.
        forceUpdate({});
        initialAPI().then(response => response)
    }, []);

    const initialAPI = async () => {
        await getClassifications()
    }

    const getClassifications = async () => axios.get(USER_MANAGEMENT_PROFILE_GET_CLASSIFICATION).then(async (response: { data: ClassificationsForm }) => {
        const { data: { naicsCodes = [], pscCodes = [], setAsideCodes: responseSetAsideCodes = []} } = response
        form.resetFields();
        setNAICSCodeOptions([])
        setPSCCodeOptions([])
        setNAICSCodes(naicsCodes);
        setPSCCodes(pscCodes);
        await getSetAsideCodes(responseSetAsideCodes);
    }).catch( (error: {}) => error )

    const getSetAsideCodes = async (responseSetAsideCodes: string[]) => axios.post(TENDER_SET_ASIDE_CODE_PAGINATE, {}, { params: { page: 0, size: 100 } }).then((response: { data: {
            items: { id: string, name: string }[],
            total: number,
        }}) => {
        const { data: { items: setAsideCodes, total} } = response
        setSetAsideCodes(setAsideCodes);
        form.setFieldsValue({ setAsideCodes: responseSetAsideCodes.map((setAsideCode: string) => _.findLast(setAsideCodes, { name: setAsideCode })?.id) });
    }).catch((error: AxiosError) => {
        const { response: { data } } = error;
        // @ts-ignore
        message.error(MessageLanguage[data.message]||data.message).then(response => response )
    })

    const onFinish = (values: ClassificationsForm) => {
        const body: ClassificationsForm = {
            naicsCodes,
            pscCodes,
        };
        // @ts-ignore
        if (values.setAsideCodes) body.setAsideCodes = values.setAsideCodes.map((setAsideCode: string) => _.findLast(setAsideCodes, { id: setAsideCode })?.name)
        axios.post(USER_MANAGEMENT_PROFILE_SET_CLASSIFICATION, body).then(async (response: {}) => {
            await getClassifications()
            // navigate('/profile/ai-files')
            message.success(MessageLanguage[Message.CHANGE_SUCCESS]).then(response => response)
        }, (error: AxiosError) => {
            const { response: { data } } = error;
            // @ts-ignore
            message.error(MessageLanguage[data.message]).then(response => response)
        })
    };

    return (
        <div className={'classifications'}>
            <Helmet>
                <title>{title} - {ClassificationsLanguage.title}</title>
            </Helmet>
            <Space direction="vertical" size={50} style={{width:'100%'}}>
                <Typography.Title level={1}>{ClassificationsLanguage.title}</Typography.Title>
                <Form layout={'vertical'} form={form} name="classifications" autoComplete="off" onFinish={onFinish}>
                    <Form.Item
                        label={ClassificationsLanguage.naicsCode}
                        name="naicsCode"
                        rules={[
                            { pattern: isNumber, message: `${ClassificationsLanguage.naicsCode} ${Validation.number}` },
                        ]}
                        extra={!_.isEmpty(naicsCodes) && (
                            <div className={'tags'}>
                                {naicsCodes.map((naicsCode: string) => <Tag key={naicsCode} color="#4BAB71" closable onClose={(event: MouseEvent) => {
                                    const naicsCodesCopy = [...naicsCodes]
                                    naicsCodesCopy.splice(naicsCodesCopy.indexOf(naicsCode), 1)
                                    setNAICSCodes(naicsCodesCopy)
                                }}>{naicsCode}</Tag>)}
                            </div>
                        )}
                    >
                        <AutoComplete
                            size="large"
                            options={naicsCodeOptions}
                            onSearch={(code) => axios.post(TENDER_NAICS_CODE_PAGINATE, { code }, { params: { page: 0, size: 10, loading: false } }).then((response: { data: { items: { code: string, title: string }[], total: number } }) => {
                                const { data: { items, total } } = response
                                if (code) setNAICSCodeOptions(items.map(({ code, title }, key) => ({ label: `${code} - ${title}`, value: code, key })))
                                else setNAICSCodeOptions([])
                            }).catch( (error: {}) => setNAICSCodeOptions([]) )}
                            onBlur={(event: any) => {
                                const naicsCode = event?.target?.value;
                                if (naicsCode && naicsCode.match(isNumber)) {
                                    setNAICSCodes(_.uniq([...naicsCodes, naicsCode]));
                                    form.resetFields(['naicsCode']);
                                }
                            }}
                        />
                    </Form.Item>
                    <Form.Item
                        label={ClassificationsLanguage.pscCode}
                        name="pscCode"
                        extra={!_.isEmpty(pscCodes) && (
                            <div className={'tags'}>
                                {pscCodes.map((pscCode: string) => <Tag key={pscCode} color="#4BAB71" closable onClose={(event: MouseEvent) => {
                                    const pscCodesCopy = [...pscCodes]
                                    pscCodesCopy.splice(pscCodesCopy.indexOf(pscCode), 1)
                                    setPSCCodes(pscCodesCopy)
                                }}>{pscCode}</Tag>)}
                            </div>
                        )}
                    >
                        <AutoComplete
                            size={'large'}
                            options={pscCodeOptions}
                            onSearch={(code) => axios.post(TENDER_PSC_CODE_PAGINATE, { code }, { params: { page: 0, size: 10, loading: false } }).then((response: { data: { items: { code: string, title: string }[], total: number } }) => {
                                const { data: { items, total } } = response
                                if (code) setPSCCodeOptions(items.map(({ code, title }, key) => ({ label: `${code} - ${title}`, value: code, key })))
                                else setPSCCodeOptions([])
                            }).catch( (error: {}) => setPSCCodeOptions([]) )}
                            onBlur={(event: any) => {
                                const pscCode = event?.target?.value;
                                if (pscCode) {
                                    setPSCCodes(_.uniq([...pscCodes, pscCode]));
                                    form.resetFields(['pscCode']);
                                }
                            }}
                        />
                    </Form.Item>
                    <Form.Item
                        label={ClassificationsLanguage.setAsideCode}
                        name="setAsideCodes"
                    >
                        <Select
                            showSearch
                            allowClear
                            mode="multiple"
                            size={'large'}
                            optionFilterProp="label"
                            options={setAsideCodes.map(({ id, name }) => ({ label: name, value: id }))}
                        />
                    </Form.Item>
                    <Flex gap={'middle'} justify={'end'} style={{marginTop:50}}>
                        <Form.Item shouldUpdate>
                            {() => (
                                <Button
                                    type="link"
                                    className={'link-button'}
                                    onClick={() => getClassifications()}
                                >
                                    {ClassificationsLanguage.cancel}
                                </Button>
                            )}
                        </Form.Item>
                        <Form.Item shouldUpdate>
                            {() => (
                                <Button
                                    disabled={
                                        _.isEmpty(naicsCodes) &&
                                        _.isEmpty(pscCodes) &&
                                        _.isEmpty(form.getFieldValue('setAsideCodes'))
                                    }
                                    htmlType="submit"
                                >
                                    <Space><span>{ClassificationsLanguage.saveChange}</span><FaCheck size={15} /></Space>
                                </Button>
                            )}
                        </Form.Item>
                    </Flex>
                </Form>
            </Space>
        </div>
    )
}
