import React, { useContext, useEffect, useState } from 'react'
import { useParams, useNavigate } from "react-router-dom"
import { Result, Button, App } from 'antd'
import { Helmet } from "react-helmet";
import { API, Enum } from "../../resources/constants";
import language from "../../resources/languages/en_US"

// Interfaces
import { AxiosError } from "../../interfaces/axios"

// Contexts
import AxiosContext from "../../contexts/axios";

const { App: { title }, Pages: { InviteMember: InviteMemberLanguage  }, Share, Validation, Message: MessageLanguage } = language
const { USER_MANAGEMENT_PROFILE_MEMBER_ADD } = API
const { Message } = Enum

export default function InviteMember() {

    const [success, setSuccess] = useState(false);
    const navigate = useNavigate()
    const { admin } = useParams()
    const { axios } = useContext(AxiosContext)
    const { message } = App.useApp()

    useEffect(() => {
        admin && axios.get(USER_MANAGEMENT_PROFILE_MEMBER_ADD + admin).then(() => {
            setSuccess(true)
        }, async (error: AxiosError) => {
            const { response: { data } } = error;
            setSuccess(false)
            // @ts-ignore
            message.error(MessageLanguage[data.message]).then(response => response )
        })
    }, []);

    return (
        <div className={'invite-member'}>
            <Helmet>
                <title>{title} - {success ? InviteMemberLanguage.titleSuccess : InviteMemberLanguage.titleError}</title>
            </Helmet>
            {
                success ? (
                    <Result
                        status="success"
                        title={InviteMemberLanguage.titleSuccess}
                        subTitle={InviteMemberLanguage.descriptionSuccess}
                        extra={<Button type="link" className={'link-button'} onClick={() => navigate('/')}>{InviteMemberLanguage.backOpportunities}</Button>}
                    />
                ) : (
                    <Result
                        status="error"
                        title={InviteMemberLanguage.titleError}
                        subTitle={InviteMemberLanguage.descriptionError}
                        extra={<Button type="link" className={'link-button'} onClick={() => navigate('/')}>{InviteMemberLanguage.backPayment}</Button>}
                    />
                )
            }
        </div>
    )
}
