import enUS from 'antd/locale/en_US'
import _ from "lodash";

const content = {
    App: {
        title: "FelixFusion",
        description: "Your Comprehensive AI Solution for Sourcing, Managing, and Submitting Goverment Contracts.",
    },
    Commons: {
        Intro: {
            title: "FelixFusion AI",
            description: "Your Comprehensive AI Solution for Sourcing, Managing, and Submitting Goverment Contracts.",
        },
        PendingRequest: {
            loading: "Receiving information from the server.",
        }
    },
    Layouts: {
        Dashboard: {
            menu: "Menu",
            dashboard: "Dashboard",
            opportunities: "Opportunities",
            proposalAiAgent: "Proposal AI Agent",
            contractManagement: "Contract Management",
            schedules: "Schedules",
            pipelines: "Pipelines",
            templates: "Templates",
            knowledgeBase: "Knowledge Base",
            profile: "Profile",
            signOut: "Sign out",
            title: "FelixFusion",
            copyright: "© 2024 | All rights reserved for FelixFusion."
        },
        Public: {
            copyright: "© 2024 | All rights reserved for FelixFusion."
        }
    },
    Pages: {
        Dashboard: {
            title: "Powered by FelixFusion AI",
            description: "Choose your desired option",
            tenders: "Tenders",
            grants: "Grants",
            todayTenders: "Today Tenders",
            usTenders: "US Tenders",
            canadaTenders: "Canada Tenders",
            europeTenders: "Europe Tenders",
            payments: "Payments",
            plan: "Plan",
            activation: "Activation",
            amount: "Amount",
            expiration: "Expiration",
        },
        InviteMember: {
            titleSuccess: "Successfully Add to Members",
            descriptionSuccess: "Welcome to FlexFusion",
            backOpportunities: "Back to opportunities",
            titleError: "Error Add to Members",
            descriptionError: "Welcome to FlexFusion",
            backPayment: "Back to payment",
        },
        Payment: {
            title: "Unlock the power of AI",
            description: "Revolutionize Your Procurement with Automation.",
            prime: "Felix Prime",
            prime1: "Popular",
            prime2: "All Features of Felix Prime Plus",
            prime3: "Tailored Opportunities Selected Based on Your Profile",
            prime4: "Full-Service Tender Management",
            contactUs: "Contact Us",
            pro: "Felix Pro",
            pro1: "Per Month",
            pro2: "Unlimited User Assignment",
            pro3: "Customized AI Search Tool",
            pro4: "AI-Powered Operating System",
            pro5: "AI Insights & Indexes",
            pro6: "Contract Management Tool",
            payStripe: "Pay with Stripe",
            frequently: "Frequently asked questions",
            question1: "What is FelixFusion?",
            answer1: "FelixFusion is an AI-enhanced procurement platform that transcends traditional tools that target specific tasks. By offering a seamless, all-inclusive solution, FelixFusion facilitates every aspect of the procurement cycle, enhancing both efficiency and strategic insight.",
            question2: "How does FelixFusion work?",
            answer2: "FelixFusion is an AI-enhanced procurement platform that transcends traditional tools that target specific tasks. By offering a seamless, all-inclusive solution, FelixFusion facilitates every aspect of the procurement cycle, enhancing both efficiency and strategic insight.",
            question3: "What kind of questions can I ask FelixFusion?",
            answer3: "You can ask FelixFusion anything you would ask a human assistant, such as weather updates, news headlines, restaurant recommendations, and more.",
            question4: "Is my data safe with FelixFusion?",
            answer4: "FelixFusion is an AI-enhanced procurement platform that transcends traditional tools that target specific tasks. By offering a seamless, all-inclusive solution, FelixFusion facilitates every aspect of the procurement cycle, enhancing both efficiency and strategic insight.",
            question5: "Can I customize FelixFusion's responses?",
            answer5: "FelixFusion is an AI-enhanced procurement platform that transcends traditional tools that target specific tasks. By offering a seamless, all-inclusive solution, FelixFusion facilitates every aspect of the procurement cycle, enhancing both efficiency and strategic insight.",
            question6: "Is FelixFusin available in multiple languages?",
            answer6: "FelixFusion is an AI-enhanced procurement platform that transcends traditional tools that target specific tasks. By offering a seamless, all-inclusive solution, FelixFusion facilitates every aspect of the procurement cycle, enhancing both efficiency and strategic insight.",
            successTitle: "Payment Successful",
            successDescription: "Thank you for completing your payment. This is a confirmation that we have just received your secure online payment. we sent you an email with a copy of your detailed invoice. Thank you!",
            successButton: "Complete Profile",
            errorTitle: "Payment Error",
            errorDescription: "Thank you for completing your payment. This is a confirmation that we have just received your secure online payment. we sent you an email with a copy of your detailed invoice. Thank you!",
            errorButton: "Back to Payment",
            buy: "Buy Subscription",
            pay1: "Pay $",
            pay2: " USD",
        },
        Opportunities: {
            title: "Opportunities",
            Filter: {
                title: "Filter By",
                useProfileFilter: "Use profile filter",
                source: "Default Source",
                activeOnly: "Active Only",
                inactiveOnly: "Inactive Only",
                dueDate: "Due Date",
                postedDate: "Posted Date",
                solicitationNumber: "Solicitation Number",
                solicitationNumberPlaceholder: "Ex: W9132T21R0002",
                buyer: "Buyer Include",
                buyerExclude: "Buyer Exclude",
                buyerPlaceholder: "Ex: Dept. of defense",
                naicsCode: "NAICS Code(s)",
                naicsCodePlaceholder: "Ex: 513210",
                pscCode: "PSC Code(s)",
                pscCodePlaceholder: "Ex: 2210",
                noticeType: "Notice Type(s)",
                noticeTypePlaceholder: "Ex: Solicitation",
                setAsideCode: "Set Aside Code(s)",
                setAsideCodePlaceholder: "Ex: 8A",
                applyFilter: "Apply Filter(s)",
                clearFilter: "Clear Filter(s)",
            },
            Search: {
                searchPlaceholder: "Search for government contracts...",
                searchButton: "Search",
                export: "Export",
                filter: "Filter",
                createSchedule: "Create Schedule",
                selectContract: "Select Contract",
                askAI: "Ask AI",
            }
        },
        SignIn: {
            title: "Sign in",
            google: "Continue with Google",
            signIn: "Sign in",
            signUp: "Create account",
            or: "OR",
            email: "Email",
            emailPlaceholder: "Enter your mail",
            password: "Password",
            passwordPlaceholder: "type your password here...",
            forgotPassword: "Forgot Password?",
            otp: "OTP",
            enableOTP: "Enable OTP",
            sendOTP: "Send OTP Code",
            submit: "Sign in with FelixFusion",
        },
        ResetPassword: {
            title: "Reset your password",
            email: "Email",
            emailPlaceholder: "Enter your mail",
            password: "Password",
            passwordPlaceholder: "type your password here...",
            passwordConfirm: "Confirm Password",
            passwordConfirmPlaceholder: "type confirm your password here...",
            resetPassword: "Reset Password",
            signIn: "Back to Sign In",
            resetPasswordNotice: "Your password has been successfully reset.",
            sendEmailNotice: "If that email address is in our database, we will send you an email to reset your password.",
            gmail: "Open Gmail",
            outlook: "Open Outlook",
            yahoo: "Open Yahoo!",
        },
        Profile: {
            title: "Profile",
            myAccount: "My Account",
            felixTechnology: "Felix Technology",
            overview: "Overview",
            classifications: "Classifications",
            aiFiles: "AI Files",
            members: "Members",
            paymentHistory: "Payment History",
            deleteAccount: "Delete account",
            MyAccount: {
                title: "My Account",
                information: "Personal Information",
                uploadTitle: "Upload New Image",
                uploadDescription: "800x800 PNG, JPG is recommended. Maximum file size: 2Mb",
                avatar: "Avatar",
                avatarFormat: "You can only upload JPG/PNG file!",
                avatarSize: "Avatar image must smaller than 2MB!",
                firstName: "First Name",
                lastName: "Last Name",
                email: "Email",
                password: "Old Password",
                newPassword: "New Password",
                newPasswordConfirm: "Confirm New Password",
                passwordDescription: "Minimum 8 characters",
                otp: "OTP",
                enableOTP: "Enable OTP",
                saveChange: "Save change",
                cancel: "Cancel",
            },
            Overview: {
                title: "Overview",
                organizationName: "Organization Name",
                description: "Description",
                descriptionHelp: "Want to generate with FelixFusion AI?",
                uei: "UEI",
                cageCode: "CAGE Code",
                contactInformation: "Contact Information",
                firstName: "First Name",
                lastName: "Last Name",
                email: "Email",
                address: "Address",
                address1: "Address Line 1",
                address2: "Address Line 2",
                city: "City",
                state: "State",
                zipCode: "Postal / ZIP Code",
                saveChange: "Save and Continue",
                cancel: "Cancel",
            },
            Classifications: {
                title: "Classifications",
                naicsCode: "NAICS Code(s)",
                pscCode: "PSC Code(s)",
                setAsideCode: "Set Aside Code(s)",
                saveChange: "Save change",
                cancel: "Cancel",
            },
            AIFiles: {
                title: "AI Files",
                aiFileFormat: "You can only upload TXT/PDF/DOC file!",
                aiFileSize: "You file must smaller than 10MB!",
                upload: "Upload to FelixFusion",
                uploadDescription: "FelixFusion AI can extract your data from the TXT/PDF/DOC file",
                uploadRecently: "Recently Uploaded",
                naicsCodeTitle: "NAICS Suggestion",
                naicsCodeSelected: "Selected NAICS Codes",
                naicsCodeSuggested: "Suggested NAICS Codes",
                naicsCode: "NAICS Code(s)",
                saveChange: "Save and Update Profile",
            },
            Members: {
                title: "Members",
                email: "Email",
                emailPlaceholder: "hi@membermail.com",
                inviteMember: "Invite Member",
                inviteNewMember: "Invite New Member",
            },
            PaymentHistory: {
                title: "Payment History",
                plan: "Plan",
                activation: "Activation",
                amount: "Amount",
                expiration: "Expiration",
                backOpportunities: "Back to opportunities",
            },
            DeleteAccount: {
                title: "Delete Account",
                description1: "We’re sorry to see you go :(",
                description2: "Warning: Deleting your account will permanently remove all of your data and cannot be undone. This includes your profile, AI chat histories, Contracts, and any other information associated with your account. Are you sure you want to proceed with deleting your account?",
                organizationName: "Organization Name",
                organizationNamePlaceholder: "Type your organization name here",
                password: "Your Password",
                passwordPlaceholder: "Type your password here",
                delete: "Delete my account",
                cancel: "Cancel",
            },
        },
        SignUp: {
            title: "Welcome to FlexFusion",
            google: "Continue with Google",
            signIn: "Sign in",
            signUp: "Create account",
            or: "OR",
            firstName: "First Name",
            firstNamePlaceholder: "John",
            lastName: "Last Name",
            lastNamePlaceholder: "Doe",
            email: "Email",
            emailPlaceholder: "Enter your mail",
            password: "Password",
            passwordPlaceholder: "type your password here...",
            passwordDescription: "At least 8 characters",
            termsOfService: "Terms of Service",
            agree: "Agree",
            disagree: "Disagree",
            termsOfService_1: "By creating an account, you agree to our",
            termsOfService_2: "Terms of Service and Privacy",
            termsOfService_3: "& Cookie Statement.",
            submit: "Join FlexFusion",
        },
        NotFound: {
            title: "Not Found",
            description: "Unfortunately, the page you requested was not found.",
        },
        Forbidden: {
            title: "Not Authorization",
            description: "Unfortunately, you do not have permission to access this page.",
        },
    },
    Validation: {
        required: "Required !",
        length: "Length",
        pattern: "Does not match the pattern",
        number: "can only be a number",
        string: "can only be a string",
        min: "Min",
        max: "Max",
        invalidEmail: "Invalid email address",
        passwordConfirm: "The new password that you entered do not match!",

    },
    Share: {
        edit: "Edit",
        add: "Add",
        delete: "Delete",
        view: "View",
        save: "Save",
        cancel: "Cancel",
        search: "Search",
        yes: "Yes",
        no: "No",
        row: "Row",
        noResults: "No Search Results Found",
        operation: "Operation",
        prev: "Prev",
        next: "Next",
        download: "Download",
        pleaseSelect: "Please Select ...",
        selectDate: "Select Date",
        paginationShowTotal: "Show total",
        deleteConfirmMemberTitle: "Are you sure to delete member?",
        deleteConfirmTitle: "Are you sure to delete the desired item?",
        deleteConfirmOk: "Ok",
        deleteConfirmCancel: "Cancel",
        attachment: "File upload location",
        attachmentDescription: "Attach your files by clicking or dropping them in this section.",
        loading: "Loading ...",
        backHome: "Back to home",
    },
    Message: {
        SIGN_IN_PROFILE: "You are logged in to your account.",
        SIGN_OUT_PROFILE: "You are logged out of your account.",
        UPDATE_PROFILE: "Your account information has been successfully change.",
        CAPTCHA_INVALID: "Captcha invalid",
        UPLOAD_SUCCESS: "File uploaded successfully",
        UPLOAD_ERROR: "There was an error loading the file",
        CHANGE_SUCCESS: "The changes were applied successfully",
        DELETE_MEMBER: "Member delete successfully",
        ADD_MEMBER: "Member add successfully",
        INVITE_MEMBER: "Invite Member successfully",
        DELETE_ACCOUNT: "Delete account successfully",
        OTP_SUCCESS: "OTP Code send to email successfully",
        USER_OTP_IS_DISABLE: "OTP disabled for this email",
        OTP_CODE_SENT: "OTP Code already sent (It is not possible to send until 10 minutes)",
        "ThrottlerException: Too Many Requests": "Too Many Requests",
        "Unauthorized": "The entered information is not verified.",
        "Not Found": "There is no such record.",
        "duplicate email user": "duplicate email address",
        "reset password token invalid": "reset password token invalid",
        "file is not uploaded": "file is not uploaded",
        "old password invalid": "old password invalid",
        "remove user invalid": "It is not possible to delete this member",
        "add user invalid": "It is not possible to add this user",
        "organization name invalid": "Organization Name invalid",
        "password invalid": "Your Password invalid",
        "It is impossible to invite this user": "It is impossible to invite this user",
    },
    Enum: {
        Plan: {
            FELIX_PRO: "Felix Pro",
            FELIX_PRIME: "Felix Prime",
        },
        Member: {
            MEMBER: "Member",
            INVITE: "Invite",
        },
    }
}

export default _.merge(enUS, content);
