import React, { useEffect, useRef } from "react";
import language from "../../resources/languages/en_US";

const { Pages: { SignUp } } = language

export default function TermsOfService(props: { setAgree: (agree: boolean) => void }) {
    const { setAgree } = props
    const modalRef  = useRef<HTMLDivElement|null>(null)

    useEffect(() => {
        modalRef?.current?.addEventListener("scrollend", (event: Event) => {
            setAgree(true);
        });
    }, []);

    return (
        <div
            ref={modalRef}
            className={'ant-modal-body-content'}
        >
            1. Introduction
            Welcome to FelixFusion, a platform operated by Felix Technology Capital ("Felix Technology", "we", "us", "our"). By accessing or using FelixFusion (the "Platform"), you agree to comply with and be bound by these Terms of Service (the "Terms"). If you do not agree to these Terms, please do not use the Platform.
            2. Use of the Platform
            2.1 Eligibility
            You must be at least 18 years old to use the Platform. By using the Platform, you represent and warrant that you meet this age requirement.
            2.2 Account Registration
            To access certain features of the Platform, you may need to create an account. You agree to provide accurate, current, and complete
            We will take appropriate measures to ensure that your personal information remains protected in accordance with this Privacy Policy.
            8. Children's Privacy
            The Platform is not intended for children under the age of 18. We do not knowingly collect personal information from children under 18. If we become aware that we have collected personal information from a child under 18, we will take steps to delete such information.
            9. Changes to the Privacy Policy
            We may update this Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on the Platform. You are advised to review this Privacy Policy periodically for any changes.
            10. Contact Us
            If you have any questions about this Privacy Policy, please contact us at [insert contact information].
            1. Introduction
            Welcome to FelixFusion, a platform operated by Felix Technology Capital ("Felix Technology", "we", "us", "our"). By accessing or using FelixFusion (the "Platform"), you agree to comply with and be bound by these Terms of Service (the "Terms"). If you do not agree to these Terms, please do not use the Platform.
            2. Use of the Platform
            2.1 Eligibility
            You must be at least 18 years old to use the Platform. By using the Platform, you represent and warrant that you meet this age requirement.
            2.2 Account Registration
            To access certain features of the Platform, you may need to create an account. You agree to provide accurate, current, and complete
            We will take appropriate measures to ensure that your personal information remains protected in accordance with this Privacy Policy.
            8. Children's Privacy
            The Platform is not intended for children under the age of 18. We do not knowingly collect personal information from children under 18. If we become aware that we have collected personal information from a child under 18, we will take steps to delete such information.
            9. Changes to the Privacy Policy
            We may update this Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on the Platform. You are advised to review this Privacy Policy periodically for any changes.
            10. Contact Us
            If you have any questions about this Privacy Policy, please contact us at [insert contact information].
            1. Introduction
            Welcome to FelixFusion, a platform operated by Felix Technology Capital ("Felix Technology", "we", "us", "our"). By accessing or using FelixFusion (the "Platform"), you agree to comply with and be bound by these Terms of Service (the "Terms"). If you do not agree to these Terms, please do not use the Platform.
            2. Use of the Platform
            2.1 Eligibility
            You must be at least 18 years old to use the Platform. By using the Platform, you represent and warrant that you meet this age requirement.
            2.2 Account Registration
            To access certain features of the Platform, you may need to create an account. You agree to provide accurate, current, and complete
            We will take appropriate measures to ensure that your personal information remains protected in accordance with this Privacy Policy.
            8. Children's Privacy
            The Platform is not intended for children under the age of 18. We do not knowingly collect personal information from children under 18. If we become aware that we have collected personal information from a child under 18, we will take steps to delete such information.
            9. Changes to the Privacy Policy
            We may update this Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on the Platform. You are advised to review this Privacy Policy periodically for any changes.
            10. Contact Us
            If you have any questions about this Privacy Policy, please contact us at [insert contact information].
        </div>
    )
}
