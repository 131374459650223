import React from 'react'
import { Outlet } from "react-router-dom"
import { Layout, theme } from 'antd'

const { Content } = Layout

export default function Index() {

    const { token: { colorBgContainer } } = theme.useToken()

    return (
        <Content style={{background: colorBgContainer}}>
            <Outlet />
        </Content>
    )
}
