import React, { useContext } from "react";
import { Navigate, useLocation } from "react-router-dom";

// Contexts
import GlobalContext from "../../contexts/global";

export default function Index(props: { children: JSX.Element }) {

    const { children } = props;
    const { user } = useContext(GlobalContext)
    const location = useLocation();

    if (user) return <Navigate to={location.state?.from?.pathname || "/"} state={{ from: location }} replace />
    else return children
}
