import React, { useContext, useEffect, useState } from "react";
import { Helmet } from 'react-helmet'
import Cookies from "js-cookie";
import {Input, Button, Form, App, Row, Col, Divider, Typography, Modal, Space, Flex} from "antd";
import { useNavigate, useLocation, Link } from "react-router-dom";
import validator from "validator";
import { ActionType, API, Enum } from "../../resources/constants";
import language from "../../resources/languages/en_US";

// Components
import TermsOfService from "./terms-of-service"

// Interfaces
import { AxiosError } from "../../interfaces/axios"

// Contexts
import GlobalContext from "../../contexts/global";
import AxiosContext from "../../contexts/axios";

// Commons
import Intro from '../../commons/intro'

const { App: { title }, Pages: { SignUp }, App: AppLanguage, Share, Validation, Message: MessageLanguage } = language
const { SET_USER, SET_ACCESS_TOKEN } = ActionType
const { USER_MANAGEMENT_AUTH_SIGN_UP, USER_MANAGEMENT_PROFILE_MY_ACCOUNT, USER_MANAGEMENT_AUTH_SIGN_IN_GOOGLE } = API
const { Message } = Enum
const baseURL = process.env.NODE_ENV === 'development' ? process.env.REACT_APP_DEVELOPMENT_API_URL : process.env.REACT_APP_PRODUCTION_API_URL

export default function Index() {

    const [form] = Form.useForm()
    const [, forceUpdate] = useState({})
    const { dispatch } = useContext(GlobalContext)
    const { axios } = useContext(AxiosContext)
    const [openModal, setOpenModal] = useState(false);
    const [agree, setAgree] = useState(false);
    const navigate = useNavigate()
    const location = useLocation()
    const { message, modal } = App.useApp()

    useEffect(() => {
        // To disable submit button at the beginning.
        forceUpdate({});
    }, []);

    const onFinish = (
        values: {
        firstName: string,
        lastName: string,
        email: string,
        password: string
        }) => {
        axios.post(USER_MANAGEMENT_AUTH_SIGN_UP, values).then((response: { data: { accessToken: string }}) => {
            const { data: { accessToken } } = response
            Cookies.set('accessToken', accessToken, { expires: 365 })
            dispatch({ type: SET_ACCESS_TOKEN , payload: { accessToken }})
            axios.get(USER_MANAGEMENT_PROFILE_MY_ACCOUNT).then((response: { data: { } }) => {
                const { data: user } = response
                dispatch({ type: SET_USER , payload: { user }})
                navigate(location.state?.from?.pathname || "/", { replace: true });
                message.success(MessageLanguage[Message.SIGN_IN_PROFILE]).then(response => response)
            })
        }, async (error: AxiosError) => {
            const { response: { data } } = error;
            form.resetFields(['captcha'])
            // @ts-ignore
            message.error(MessageLanguage[data.message]).then(response => response )
        })
    };

    return (
        <div>
            <Helmet>
                <title>{title} - {SignUp.title}</title>
            </Helmet>
            <Modal
                className={'terms-of-service-modal'}
                width={550}
                destroyOnClose={true}
                title={SignUp.termsOfService}
                open={openModal}
                cancelText={SignUp.disagree}
                okText={SignUp.agree}
                cancelButtonProps={{
                    block: true,
                    type: 'link',
                    className: 'link-button'
                }}
                okButtonProps={{
                    block: true,
                    type: 'default',
                    disabled: !agree,
                }}
                onOk={() => setOpenModal(false)}
                onCancel={() => setOpenModal(false)}
            >
                <TermsOfService setAgree={setAgree} />
            </Modal>
            <Row>
            <Col xs={24} sm={24} lg={24} xl={14} xxl={9}>
               <Intro />
            </Col>
            <Col className={'wrapper-white'} xs={24} sm={24} lg={24} xl={10} xxl={15} >
                <div className={'sign-in sign-up'}>
                    <Space direction="vertical" size="middle">
                        <div className={'logo'}>
                            <img src={require('../../resources/media/logo-black.png')} width={200}/>
                        </div>
                        <Flex gap="small" className={'switch-button'}>
                            <Button size={'small'} type="text" block onClick={() => navigate('/sign-in')} >{SignUp.signIn}</Button>
                            <Button size={'small'} block onClick={() => navigate('/sign-up')} >{SignUp.signUp}</Button>
                        </Flex>
                        <Button icon={<img src={require('../../resources/media/social/google.png')} width={24}/>} block onClick={() => window.location.replace(`${baseURL}${USER_MANAGEMENT_AUTH_SIGN_IN_GOOGLE}`)}>{SignUp.google}</Button>
                        <Divider>{SignUp.or}</Divider>
                        <Form layout={'vertical'} form={form} name="sign-in" autoComplete="off" onFinish={onFinish}>
                            <Row gutter={20}>
                                <Col xs={24} sm={24} lg={24} xl={12} xxl={12}>
                                    <Form.Item
                                        label={SignUp.firstName}
                                        name="firstName"
                                        rules={[
                                            { required: true, message: `${SignUp.firstName} ${Validation.required}` },
                                            { min: 3, message: `${SignUp.firstName} ${Validation.min} 3 ${Validation.length}` },
                                            { max: 30, message: `${SignUp.firstName} ${Validation.max} 30 ${Validation.length}` },
                                        ]}
                                    >
                                        <Input maxLength={30} placeholder={SignUp.firstNamePlaceholder} />
                                    </Form.Item>
                                </Col>
                                <Col xs={24} sm={24} lg={24} xl={12} xxl={12}>
                                    <Form.Item
                                        label={SignUp.lastName}
                                        name="lastName"
                                        rules={[
                                            { required: true, message: `${SignUp.lastName} ${Validation.required}` },
                                            { min: 3, message: `${SignUp.lastName} ${Validation.min} 3 ${Validation.length}` },
                                            { max: 30, message: `${SignUp.lastName} ${Validation.max} 30 ${Validation.length}` },
                                        ]}
                                    >
                                        <Input maxLength={30} placeholder={SignUp.lastNamePlaceholder} />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Form.Item
                                label={SignUp.email}
                                name="email"
                                rules={[
                                    { required: true, message: `${SignUp.email} ${Validation.required}` },
                                    ({ getFieldValue }) => ({
                                        validator(_, value) {
                                            if (!value || validator.isEmail(value)) return Promise.resolve()
                                            else return Promise.reject(new Error(Validation.invalidEmail))
                                        },
                                    }),
                                ]}
                            >
                                <Input placeholder={SignUp.emailPlaceholder} />
                            </Form.Item>
                            <Form.Item
                                label={SignUp.password}
                                name="password"
                                rules={[
                                    { required: true, message: `${SignUp.password} ${Validation.required}` },
                                    { min: 8, message: `${SignUp.password} ${Validation.min} 8 ${Validation.length}` },
                                    { max: 100, message: `${SignUp.password} ${Validation.max} 100 ${Validation.length}` },
                                ]}
                                extra={<span>{SignUp.passwordDescription}</span>}
                            >
                                <Input.Password placeholder={SignUp.passwordPlaceholder} />
                            </Form.Item>
                            <Form.Item shouldUpdate>
                                {() => (
                                    <Button
                                        block
                                        type="primary"
                                        htmlType="submit"
                                    >
                                        { SignUp.submit }
                                    </Button>
                                )}
                            </Form.Item>
                        </Form>
                        <div className={'terms-of-service'}>
                            <Typography.Text>{SignUp.termsOfService_1}</Typography.Text>
                            <Typography.Link onClick={() => setOpenModal(true)}>{SignUp.termsOfService_2}</Typography.Link>
                            <Typography.Text>{SignUp.termsOfService_3}</Typography.Text>
                        </div>
                    </Space>
                </div>
            </Col>
        </Row>
        </div>
    )
}
